<template>
  <div class="app__toolbar-wrapper" role="banner">
    <div class="app__toolbar above" :class="{ full_width: onHome && !modal }">
      <div class="app-toolbar-inner">
        <TheSoundButton v-if="!modal" />

        <BylineButton v-if="byline && !modal" :text="byline" />
      </div>
      <div v-if="onHome" class="app-toolbar-inner">
        <LanguagePickerButton v-if="!modal" />
      </div>
    </div>

    <div v-if="!inGame" class="app__toolbar below">
      <BaseButton
        v-if="privacyPolicyUrl"
        :href="privacyPolicyUrl"
        new-tab
        is-quaternary
        >{{ $l10n('privacy-policy') }}</BaseButton
      >

      <BaseButton
        v-if="termsOfUseUrl"
        :href="termsOfUseUrl"
        new-tab
        is-quaternary
        >{{ $l10n('terms-of-use') }}</BaseButton
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inGame: Boolean,
    onHome: Boolean,
    byline: {
      type: String,
      default: '',
    },
    privacyPolicyUrl: {
      type: String,
      default: '',
    },
    termsOfUseUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    modal() {
      const modals = this.$store.state.activeModals;
      if (modals.length > 0) return true;
      return false;
    },
  },
};
</script>
