import { Howl } from 'howler';

class Soundboard {
  constructor(source) {
    this.source = source;
  }

  play(clip) {
    const file = this.source[clip];
    const howl = new Howl({
      src: file,
      volume: 0.5,
    });
    howl.play();
  }
}

export default {
  install(Vue, source) {
    Vue.prototype.$sounds = new Soundboard(source);
  },
};
