<template>
  <div class="frame__container">
    <div class="frame">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {},
  computed: {
    ...mapState(['currentScreen', 'activeModals']),

    tag() {
      return this.href ? 'a' : 'button';
    },
    attrs() {
      return this.href
        ? {
            href: this.href,
            target: this.newTab ? '_blank' : undefined,
          }
        : {};
    },
    isParentActive() {
      // Modals open, only the current modal gets focusable buttons
      if (this.activeModals.length > 0) {
        return this.activeModals.includes(this.parentView);
      }

      // No parent view (root), allow
      if (!this.parentView) {
        return true;
      }

      // Must belong to the current screen
      return this.parentView === this.currentScreen;
    },
  },
};
</script>
