<template>
  <BaseButton
    name="byline"
    location="main"
    class="byline"
    is-tertiary
    data-cy="bylineButton"
    @click="showModal('about')"
    >{{ text }}</BaseButton
  >
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
