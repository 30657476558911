<template functional>
  <svg :class="['emote', 'emote--' + props.face]" viewBox="0 0 76 76">
    <g v-if="props.face === 'very-upset'" class="emote__face">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 176 176"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M76.7801 152.08C118.732 152.08 152.74 118.072 152.74 76.1201C152.74 34.1686 118.732 0.160156 76.7801 0.160156C34.8285 0.160156 0.820068 34.1686 0.820068 76.1201C0.820068 118.072 34.8285 152.08 76.7801 152.08Z"
          fill="#F14124"
        />
        <path
          d="M40.36 80.4603C47.0371 80.4603 52.45 75.0474 52.45 68.3702C52.45 61.6931 47.0371 56.2803 40.36 56.2803C33.6829 56.2803 28.27 61.6931 28.27 68.3702C28.27 75.0474 33.6829 80.4603 40.36 80.4603Z"
          fill="#1A1A1A"
        />
        <path
          d="M98.7699 60.0101C105.447 60.0101 110.86 54.5972 110.86 47.9201C110.86 41.243 105.447 35.8301 98.7699 35.8301C92.0928 35.8301 86.6799 41.243 86.6799 47.9201C86.6799 54.5972 92.0928 60.0101 98.7699 60.0101Z"
          fill="#1A1A1A"
        />
        <path
          d="M59.6499 128.23C58.6965 128.23 57.7668 127.933 56.9893 127.381C56.2118 126.83 55.6249 126.05 55.3099 125.15C53.7754 120.767 53.1192 116.125 53.3788 111.488C53.6383 106.852 54.8086 102.312 56.8227 98.1278C60.8903 89.6774 68.1483 83.189 76.9999 80.09C85.8515 76.9909 95.5717 77.5351 104.022 81.6027C112.472 85.6704 118.961 92.9284 122.06 101.78C122.405 102.918 122.295 104.146 121.754 105.206C121.213 106.265 120.283 107.074 119.159 107.462C118.034 107.85 116.803 107.787 115.724 107.287C114.644 106.787 113.801 105.889 113.37 104.78C111.073 98.2331 106.27 92.8667 100.016 89.8614C93.7629 86.8562 86.5718 86.4582 80.0249 88.7549C73.478 91.0517 68.1117 95.8552 65.1064 102.109C62.1012 108.362 61.7031 115.553 63.9999 122.1C64.4025 123.254 64.3305 124.52 63.7999 125.621C63.2692 126.721 62.3233 127.566 61.1699 127.97C60.6822 128.144 60.1678 128.232 59.6499 128.23Z"
          fill="#1A1A1A"
        />
      </svg>
    </g>
    <g v-if="props.face === 'upset'" class="emote__face">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 176 176"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M76.8399 152.08C118.791 152.08 152.8 118.072 152.8 76.1201C152.8 34.1686 118.791 0.160156 76.8399 0.160156C34.8883 0.160156 0.879883 34.1686 0.879883 76.1201C0.879883 118.072 34.8883 152.08 76.8399 152.08Z"
          fill="#F4B0CC"
        />
        <path
          d="M45.4801 68.8602C52.1573 68.8602 57.5701 63.4473 57.5701 56.7702C57.5701 50.0931 52.1573 44.6802 45.4801 44.6802C38.803 44.6802 33.3901 50.0931 33.3901 56.7702C33.3901 63.4473 38.803 68.8602 45.4801 68.8602Z"
          fill="#1A1A1A"
        />
        <path
          d="M107.36 68.8602C114.037 68.8602 119.45 63.4473 119.45 56.7702C119.45 50.0931 114.037 44.6802 107.36 44.6802C100.683 44.6802 95.27 50.0931 95.27 56.7702C95.27 63.4473 100.683 68.8602 107.36 68.8602Z"
          fill="#1A1A1A"
        />
        <path
          d="M104.15 104.27C103.343 104.279 102.548 104.075 101.846 103.678C101.144 103.281 100.559 102.706 100.15 102.01C97.8457 98.0904 94.5575 94.8408 90.611 92.5829C86.6645 90.325 82.1966 89.1373 77.6499 89.1373C73.1032 89.1373 68.6353 90.325 64.6888 92.5829C60.7424 94.8408 57.4541 98.0904 55.1499 102.01C54.8464 102.537 54.4412 102.999 53.9579 103.369C53.4746 103.739 52.9227 104.009 52.3342 104.164C51.7457 104.319 51.1323 104.356 50.5296 104.272C49.9269 104.188 49.3468 103.985 48.8229 103.676C48.2989 103.366 47.8417 102.956 47.4775 102.468C47.1134 101.981 46.8496 101.426 46.7014 100.836C46.5533 100.245 46.5237 99.6315 46.6144 99.0298C46.7051 98.4281 46.9143 97.8503 47.2299 97.33C50.3478 92.0339 54.7943 87.6435 60.1296 84.5933C65.465 81.543 71.5042 79.9385 77.6499 79.9385C83.7956 79.9385 89.8349 81.543 95.1702 84.5933C100.506 87.6435 104.952 92.0339 108.07 97.33C108.488 98.0309 108.711 98.8306 108.717 99.6466C108.723 100.463 108.512 101.265 108.104 101.972C107.697 102.679 107.108 103.265 106.399 103.669C105.69 104.073 104.886 104.28 104.07 104.27H104.15Z"
          fill="#1A1A1A"
        />
      </svg>
    </g>
    <g v-if="props.face === 'neutral'" class="emote__face">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 176 176"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M76.9 152.08C118.852 152.08 152.86 118.072 152.86 76.1201C152.86 34.1686 118.852 0.160156 76.9 0.160156C34.9484 0.160156 0.939941 34.1686 0.939941 76.1201C0.939941 118.072 34.9484 152.08 76.9 152.08Z"
          fill="#FAB334"
        />
        <path
          d="M44.7198 68.8602C51.397 68.8602 56.8099 63.4473 56.8099 56.7702C56.8099 50.0931 51.397 44.6802 44.7198 44.6802C38.0427 44.6802 32.6299 50.0931 32.6299 56.7702C32.6299 63.4473 38.0427 68.8602 44.7198 68.8602Z"
          fill="#1A1A1A"
        />
        <path
          d="M106.6 68.8602C113.277 68.8602 118.69 63.4473 118.69 56.7702C118.69 50.0931 113.277 44.6802 106.6 44.6802C99.9229 44.6802 94.51 50.0931 94.51 56.7702C94.51 63.4473 99.9229 68.8602 106.6 68.8602Z"
          fill="#1A1A1A"
        />
        <path
          d="M107.78 103.18H44.31C43.6872 103.209 43.065 103.111 42.481 102.893C41.897 102.675 41.3634 102.34 40.9124 101.91C40.4613 101.479 40.1024 100.962 39.8571 100.389C39.6118 99.8154 39.4854 99.1984 39.4854 98.5749C39.4854 97.9514 39.6118 97.3344 39.8571 96.7612C40.1024 96.188 40.4613 95.6706 40.9124 95.2401C41.3634 94.8096 41.897 94.4751 42.481 94.2568C43.065 94.0384 43.6872 93.9408 44.31 93.9698H107.78C108.964 94.025 110.082 94.5343 110.9 95.3919C111.719 96.2495 112.175 97.3894 112.175 98.5749C112.175 99.7604 111.719 100.9 110.9 101.758C110.082 102.615 108.964 103.125 107.78 103.18Z"
          fill="#1A1A1A"
        />
      </svg>
    </g>
    <g v-if="props.face === 'happy'" class="emote__face">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 176 176"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M76.95 152.08C118.902 152.08 152.91 118.072 152.91 76.1201C152.91 34.1686 118.902 0.160156 76.95 0.160156C34.9985 0.160156 0.98999 34.1686 0.98999 76.1201C0.98999 118.072 34.9985 152.08 76.95 152.08Z"
          fill="#BFE6D6"
        />
        <path
          d="M44.77 68.8602C51.4471 68.8602 56.8599 63.4473 56.8599 56.7702C56.8599 50.0931 51.4471 44.6802 44.77 44.6802C38.0928 44.6802 32.6799 50.0931 32.6799 56.7702C32.6799 63.4473 38.0928 68.8602 44.77 68.8602Z"
          fill="#1A1A1A"
        />
        <path
          d="M106.65 68.8602C113.327 68.8602 118.74 63.4473 118.74 56.7702C118.74 50.0931 113.327 44.6802 106.65 44.6802C99.973 44.6802 94.5601 50.0931 94.5601 56.7702C94.5601 63.4473 99.973 68.8602 106.65 68.8602Z"
          fill="#1A1A1A"
        />
        <path
          d="M76.1599 104.27C70.0186 104.248 63.9879 102.635 58.6563 99.5869C53.3247 96.5391 48.8745 92.1609 45.7399 86.8799C45.4319 86.3598 45.2294 85.7842 45.1439 85.1859C45.0584 84.5876 45.0916 83.9783 45.2416 83.3929C45.5445 82.2104 46.3046 81.1968 47.3549 80.5749C48.4051 79.9529 49.6595 79.7737 50.8419 80.0766C52.0243 80.3794 53.0379 81.1396 53.6599 82.1898C55.9621 86.1119 59.2497 89.3641 63.1964 91.6238C67.1432 93.8835 71.612 95.0724 76.1599 95.0724C80.7077 95.0724 85.1766 93.8835 89.1234 91.6238C93.0701 89.3641 96.3576 86.1119 98.6599 82.1898C99.2818 81.1396 100.296 80.3794 101.478 80.0766C102.66 79.7737 103.915 79.9529 104.965 80.5749C106.015 81.1968 106.775 82.2104 107.078 83.3929C107.381 84.5753 107.202 85.8296 106.58 86.8799C103.446 92.1616 98.996 96.5403 93.6642 99.5882C88.3325 102.636 82.3013 104.249 76.1599 104.27Z"
          fill="#1A1A1A"
        />
      </svg>
    </g>
    <g v-if="props.face === 'very-happy'" class="emote__face">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 176 176"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M76.0101 152.08C117.962 152.08 151.97 118.072 151.97 76.1201C151.97 34.1686 117.962 0.160156 76.0101 0.160156C34.0585 0.160156 0.0500488 34.1686 0.0500488 76.1201C0.0500488 118.072 34.0585 152.08 76.0101 152.08Z"
          fill="#60C099"
        />
        <path
          d="M55.4999 58.43C62.1771 58.43 67.59 53.0171 67.59 46.34C67.59 39.6629 62.1771 34.25 55.4999 34.25C48.8228 34.25 43.4099 39.6629 43.4099 46.34C43.4099 53.0171 48.8228 58.43 55.4999 58.43Z"
          fill="#1A1A1A"
        />
        <path
          d="M112.42 82.7C119.097 82.7 124.51 77.2871 124.51 70.61C124.51 63.9329 119.097 58.52 112.42 58.52C105.743 58.52 100.33 63.9329 100.33 70.61C100.33 77.2871 105.743 82.7 112.42 82.7Z"
          fill="#1A1A1A"
        />
        <path
          d="M72.9999 119.38C67.1578 119.373 61.4085 117.918 56.2661 115.145C51.1237 112.373 46.7483 108.369 43.531 103.493C40.3137 98.6166 38.3548 93.019 37.8294 87.2006C37.3039 81.3821 38.2283 75.5241 40.5199 70.1502C40.9986 69.027 41.9039 68.14 43.0366 67.6843C44.1693 67.2286 45.4367 67.2414 46.5599 67.7202C47.6831 68.1989 48.5701 69.1042 49.0258 70.2369C49.4815 71.3697 49.4686 72.637 48.9899 73.7602C46.4051 80.1164 46.4212 87.2337 49.0346 93.5782C51.6481 99.9228 56.6496 104.986 62.9614 107.678C69.2731 110.37 76.3898 110.473 82.7774 107.967C89.1651 105.461 94.3123 100.546 97.1099 94.2802C97.5886 93.157 98.4939 92.27 99.6266 91.8143C100.759 91.3586 102.027 91.3714 103.15 91.8502C104.273 92.3289 105.16 93.2342 105.616 94.3669C106.072 95.4997 106.059 96.767 105.58 97.8902C102.85 104.269 98.3075 109.705 92.5158 113.526C86.724 117.346 79.9381 119.382 72.9999 119.38Z"
          fill="#1A1A1A"
        />
      </svg>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    face: {
      type: String,
      required: true,
    },
    fill: {
      type: String,
      required: true,
    },
  },
};
</script>
