<template>
  <BaseScreen
    v-if="title"
    name="howThisWorks"
    class="avatarscreen"
    :transition="{
      name: 'slide-in',
    }"
  >
    <BaseFrame>
      <div>
        <div class="avatarscreen__header">
          <h2 class="avatarscreen__title" v-html="title" />
        </div>
        <div class="avatarscreen__body">
          <BaseCarousel
            with-pagination
            :position="position"
            @update="nextManager"
          >
            <figure
              v-for="avatar in avatars"
              :key="avatar.name"
              class="avatar-slide"
            >
              <figcaption class="avatar-slide__caption">
                <div v-if="avatar.label" class="avatar-slide__title">
                  {{ avatar.label }}
                </div>
                <div class="image__container">
                  <img v-for="pic in avatar.images" :key="pic" :src="pic" />
                </div>
                <p v-if="avatar.description" class="avatar-slide__description">
                  <span v-html="avatar.description"></span>
                </p>
              </figcaption>
            </figure>
          </BaseCarousel>
        </div>
      </div>
      <div class="avatarscreen__footer">
        <BaseButton
          name="continue"
          location="howThisWorks"
          data-cy="continueHow"
          is-rounded
          is-blue
          color="green"
          @click="nextManager"
        >
          {{ this.continue }}
        </BaseButton>
        <BaseButton
          name="back"
          is-plain
          is-padded
          location="howThisWorks"
          color="green"
          is-quaternary
          @click="goToScreen('home')"
        >
          {{ goBack }}
        </BaseButton>
      </div>
    </BaseFrame>
  </BaseScreen>
</template>

<script>
import BaseCarousel from './BaseCarousel';
import BaseFrame from './BaseFrame';
import flagRed from '../../assets/img/flag-red.svg';
import flagGreen from '../../assets/img/flag-green.svg';
import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    BaseCarousel,
    BaseFrame,
  },
  data() {
    return {
      position: 0,
    };
  },
  computed: {
    ...mapState({
      avatars: (state, i) => {
        if (!state.survey.intro_survey) return [];
        return state.survey.intro_survey.questions.map(value => {
          let images = [];
          const title = value.name;
          if (title === 'red-flag') images = [flagRed];
          if (title === 'green-flag') images = [flagGreen];
          if (title === 'both-flags') images = [flagRed, flagGreen];
          return {
            description: value.text,
            name: value.name || `slide${i}`,
            images: images,
          };
        });
      },
      title: state => {
        if (state.survey.intro_survey) return state.survey.intro_survey.preface;
        else return null;
      },
      continue: state => state.survey.translations.continue,
      goBack: state => state.survey.translations['go-back'],
    }),
  },
  methods: {
    ...mapMutations(['goToScreen']),
    nextManager(index) {
      if (typeof index === 'number') {
        this.position = index;
      } else {
        if (this.position === this.avatars.length - 1) {
          this.$emit('next');
          setTimeout(() => {
            this.position = 0;
          }, 1000);
        } else {
          this.position = this.position + 1;
        }
      }
    },
  },
};
</script>
