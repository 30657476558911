<template>
  <div
    data-cy="clickzone"
    :class="{
      'draggable-hand': true,
      'is-utopia': isUtopia,
      [type]: true,
      [`is-color-${color}`]: true,
      animating: isAnimating,
      dragging: isDragging,
      'in-range': isInRange,
    }"
  >
    <BaseButton
      v-if="withHolster"
      is-plain
      :class="{
        'draggable-hand__holster': true,
        'is-utopia': isUtopia,
      }"
      :data-cy="label.toLowerCase() + 'hand'"
      @click="select"
    >
      <!-- Change to true/false and lower it down -->
      <div class="draggable-hand__holster__label">{{ label }}</div>
    </BaseButton>
    <div
      ref="handle"
      :class="{
        'draggable-hand__handle': true,
        'is-utopia': isUtopia,
      }"
    >
      <BaseButton
        v-if="!withHolster"
        :data-cy="label + 'hand'"
        is-plain
        class="draggable-hand__handle__label"
        @click="select"
        >{{ label }}</BaseButton
      >
      <Component
        :is="uiComponent"
        :color="color"
        :is-open="isInRange"
        :is-left="type == 'left' ? true : false"
      />
    </div>
    <svg ref="arm" class="is-utopia draggable-hand__arm">
      <defs>
        <pattern
          :id="'pattern-' + type"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(120)"
          width="50"
          height="50"
        >
          <image :href="armPattern" x="0" y="0" width="50" height="50" />
        </pattern>
      </defs>
      <path
        :class="'is-utopia draggable-hand__arm__fill ' + type"
        :d="armPath.main"
      />
      <!-- <path class="draggable-hand__arm__top" :d="armPath.top" />
      <path class="draggable-hand__arm__bottom" :d="armPath.bottom" /> -->
    </svg>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';

gsap.registerPlugin(Draggable);

import checkIntersect from '../utilities/check-intersect';

// Half-assed check for mobile safari
const ua = navigator.userAgent.toLowerCase();
const needSafariHack = ua.indexOf('safari') >= 0 && ua.indexOf('chrome') < 0;

const ANCHOR_BEZIER_WEIGHT = 0;
const HANDLE_BEZIER_WEIGHT = 0;

const ANCHOR_BEZIER_OFFSET = 0;
const HANDLE_BEZIER_OFFSET = 70;

const pattern1 = require('../../assets/img/utopia/pattern-tile-1.png');
const pattern2 = require('../../assets/img/utopia/pattern-tile-2.png');

export default {
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '',
    },
    target: {
      type: HTMLElement,
      default: null,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    margin: {
      type: Number,
      default: 0,
    },
    withHolster: Boolean,
    name: String,
  },
  data() {
    return {
      draggable: null,
      isAnimating: false,
      isDragging: false,
      isInRange: false,

      anchorX: 0,
      anchorY: 0,
      handleX: 0,
      handleY: 0,

      alignment: ['left', 'top-left', 'bottom-left'].includes(this.type)
        ? 'left'
        : 'right',
      isThumb: this.name === 'Flags' ? false : true,
      isUtopia: true,
      armPattern: this.type === 'left' ? pattern1 : pattern2,
    };
  },
  computed: {
    armPath() {
      const x1 = this.anchorX;
      const y1 = this.anchorY;
      const x4 = this.handleX;
      const y4 = this.handleY;

      let x2 = x1;
      let y2 = y1;
      let x3 = x4;
      let y3 = y4;

      let dx1 = Math.abs(x4 - x1) * ANCHOR_BEZIER_WEIGHT;
      let dx2 = Math.abs(x4 - x1) * HANDLE_BEZIER_WEIGHT;

      dx1 += ANCHOR_BEZIER_OFFSET;
      dx2 += HANDLE_BEZIER_OFFSET;

      // Flip dx values if aligned left
      if (this.alignment === 'left') {
        dx1 *= -1;
        dx2 *= -1;
      }

      x2 -= dx1;
      x3 += dx2;

      const topData = `M${x1 + 40} ${y1 + 40} C ${x2 + 40} ${y2 + 40} ${
        x3 + 40
      } ${y3 + 40} ${x4} ${y4}`;
      const data = `M${x1} ${y1} C ${x2} ${y2} ${x3} ${y3} ${x4} ${y4}`;
      const bottomData = `M${x1 - 40} ${y1 - 40} C ${x2 - 40} ${y2 - 40} ${
        x3 - 40
      } ${y3 - 40} ${x4 - 40} ${y4 - 40}`;

      return {
        top: topData,
        main: data,
        bottom: bottomData,
      };
    },
    uiComponent() {
      return this.isThumb ? 'HandGraphicThumbs' : 'HandGraphicFlags';
    },
  },
  watch: {
    enabled(isEnabled) {
      if (this.draggable) {
        if (isEnabled) {
          this.draggable.enable();
        } else {
          this.draggable.disable();
        }
      }
      this.calculateAnchors();
    },
  },
  mounted() {
    const handle = this.$refs.handle;

    const [drag] = Draggable.create(handle, {
      zIndexBoost: false,
      onPress: () => {
        this.isDragging = true;
        this.$emit('update', this.isDragging, this);
      },
      onRelease: () => {
        this.isDragging = false;
        this.$emit('update', this.isDragging, this);
      },
      onDragStart: () => {
        this.$sounds.play('pickup');
      },
      onDrag: () => {
        this.checkIntersect();
        this.calculateAnchors();
        this.$emit('drag', this.isInRange, this);

        // Constantly change the left px of the arm canvas,
        // in order to force a proper repaint sans-artifacts
        if (needSafariHack) {
          this.$refs.arm.style.left = Math.random() + 'px';
        }
      },
      onDragEnd: () => {
        this.checkIntersect();
        this.$emit('drop', this.isInRange, this);
      },
    });

    this.draggable = drag;
    this.calculateAnchors();

    this.$nextTick(() => {
      window.addEventListener('resize', this.calculateAnchors);
    });
  },
  methods: {
    calculateAnchors() {
      const handle = this.$refs.handle.getBoundingClientRect();
      const canvas = document.body.getBoundingClientRect();

      let x, y, z;
      switch (this.type) {
        case 'left':
          x = 0;
          y = 1;
          z = -1;
          break;

        case 'right':
          x = 1;
          y = 1;
          z = 1;
          break;

        case 'top-left':
          x = -235;
          y = 60;
          break;

        case 'top-right':
          x = 220;
          y = 60;
          break;

        case 'bottom-left':
          x = -100;
          y = 60;
          break;

        case 'bottom-right':
          x = 100;
          y = 60;
          break;
      }

      this.anchorX = canvas.width * x + 30 * z;
      this.anchorY = canvas.height * y - 60;

      this.handleX = handle.left + handle.width / 2;
      this.handleY = handle.top + handle.height / 2;
    },
    checkIntersect() {
      if (this.target) {
        this.isInRange = checkIntersect(
          this.$refs.handle,
          this.target,
          this.margin
        );
      }
    },
    reset() {
      if (!this.draggable) {
        return;
      }

      this.isAnimating = true;
      this.draggable.disable();
      gsap.to(this.$refs.handle, {
        x: 0,
        y: 0,
        ease: 'power2.out',
        duration: 0.4,
        onUpdate: () => {
          this.calculateAnchors();

          // Constantly change the left px of the arm canvas,
          // in order to force a proper repaint sans-artifacts
          if (needSafariHack) {
            this.$refs.arm.style.left = Math.random() + 'px';
          }
        },
        onComplete: () => {
          this.calculateAnchors();
          this.isAnimating = false;

          this.draggable.update();
          if (this.enabled) {
            this.draggable.enable();
          }
        },
      });
    },
    select(event) {
      // We only want keyboard "clicks"
      if (event.detail) {
        return;
      }

      this.isInRange = true;
      this.$emit('drop', true, this);
    },
  },
};
</script>
