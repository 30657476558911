var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
    'draggable-hand': true,
    'is-utopia': _vm.isUtopia
  }, _obj[_vm.type] = true, _obj[("is-color-" + _vm.color)] = true, _obj.animating = _vm.isAnimating, _obj.dragging = _vm.isDragging, _obj['in-range'] =  _vm.isInRange, _obj ),attrs:{"data-cy":"clickzone"}},[(_vm.withHolster)?_c('BaseButton',{class:{
      'draggable-hand__holster': true,
      'is-utopia': _vm.isUtopia,
    },attrs:{"is-plain":"","data-cy":_vm.label.toLowerCase() + 'hand'},on:{"click":_vm.select}},[_c('div',{staticClass:"draggable-hand__holster__label"},[_vm._v(_vm._s(_vm.label))])]):_vm._e(),_c('div',{ref:"handle",class:{
      'draggable-hand__handle': true,
      'is-utopia': _vm.isUtopia,
    }},[(!_vm.withHolster)?_c('BaseButton',{staticClass:"draggable-hand__handle__label",attrs:{"data-cy":_vm.label + 'hand',"is-plain":""},on:{"click":_vm.select}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c(_vm.uiComponent,{tag:"Component",attrs:{"color":_vm.color,"is-open":_vm.isInRange,"is-left":_vm.type == 'left' ? true : false}})],1),_c('svg',{ref:"arm",staticClass:"is-utopia draggable-hand__arm"},[_c('defs',[_c('pattern',{attrs:{"id":'pattern-' + _vm.type,"patternUnits":"userSpaceOnUse","patternTransform":"rotate(120)","width":"50","height":"50"}},[_c('image',{attrs:{"href":_vm.armPattern,"x":"0","y":"0","width":"50","height":"50"}})])]),_c('path',{class:'is-utopia draggable-hand__arm__fill ' + _vm.type,attrs:{"d":_vm.armPath.main}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }