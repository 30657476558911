<template>
  <transition>
    <div
      v-show="isOpen"
      :class="{
        modal: true,
        'is-open': isOpen,
        'is-mini': isMini,
        'is-bubble': isBubble,
        [className]: true,
      }"
      tabindex="-1"
      @keyup.esc="close"
      @click="maybeClose"
    >
      <div class="modal__frame">
        <div v-if="content" class="modal__body" v-html="content" />
        <div v-else-if="body" class="modal__body">
          <slot />
        </div>
        <slot v-else />

        <BaseButton
          ref="close"
          name="close"
          :location="name"
          icon="close"
          is-primary
          is-circular
          data-cy="closeModal"
          class="modal__close"
          @click="close"
          >{{ $l10n('close') }}</BaseButton
        >
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  provide() {
    return {
      parentView: this.name,
    };
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    body: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    isMini: Boolean,
    isBubble: Boolean,
    autoShow: Boolean,
  },
  computed: mapState({
    isOpen(state) {
      return state.activeModals.includes(this.name);
    },
  }),
  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.$emit('show');
        this.$nextTick(() => this.$el.focus());
      } else {
        this.$el.blur();
        this.$emit('hide');
      }

      this.$emit('toggle', isOpen);
    },
  },
  mounted() {
    if (this.autoShow) {
      this.showModal(this.name);
    }
  },
  methods: mapMutations({
    close(commit) {
      this.$emit('close');
      commit('hideModal', this.name);
    },
    maybeClose(commit, e) {
      if (e.target === this.$el) {
        this.close(commit);
      }
    },
  }),
};
</script>
