<template>
  <Component
    :is="tag"
    :type="tag === 'button' ? type : null"
    :disabled="disabled"
    :class="{
      button: !isPlain,

      'is-primary': isPrimary,
      'is-secondary': isSecondary,
      'is-tertiary': isTertiary,
      'is-quaternary': isQuaternary,
      'is-link': isLink,

      'is-large': isLarge,
      'is-rounded': isRounded,
      'is-circular': isCircular,
      'is-plain': isPlain,

      ['is-color-' + color]: color,
      'is-blue': isBlue,
      'is-padded': isPadded,

      'is-disabled': disabled,
      'with-icon': !!icon,
      'with-icon-and-text': iconWithText,
    }"
    :tabindex="isParentActive ? null : -1"
    v-bind="attrs"
    @click="onClick"
  >
    <div v-if="icon" class="button__inner">
      <BaseIcon :type="icon" />

      <slot v-if="iconWithText" />
      <ScreenReaderText v-else>
        <slot />
      </ScreenReaderText>
    </div>
    <div v-else-if="!isPlain && !isLink" class="button__inner">
      <slot />
    </div>
    <slot v-else />
  </Component>
</template>

<script>
import { mapState } from 'vuex';

export default {
  inject: ['parentView'],
  props: {
    name: {
      type: String,
      default: '',
    },
    location: {
      type: String,
      default: '',
    },
    question: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
    },
    href: {
      type: String,
      default: '',
    },
    newTab: Boolean,

    // Roll styles
    isPrimary: Boolean,
    isSecondary: Boolean,
    isTertiary: Boolean,
    isQuaternary: Boolean,
    isLink: Boolean,

    // Shape styles
    isLarge: Boolean,
    isRounded: Boolean,
    isCircular: Boolean,

    // Misc Styles
    isPlain: Boolean,
    iconWithText: Boolean,
    isBlue: Boolean,
    isPadded: Boolean,
    needsSession: Boolean,
  },
  computed: {
    ...mapState(['currentScreen', 'activeModals', 'session']),

    tag() {
      return this.href ? 'a' : 'button';
    },
    attrs() {
      return this.href
        ? {
            href: this.href,
            target: this.newTab ? '_blank' : undefined,
          }
        : {};
    },
    isParentActive() {
      // Modals open, only the current modal gets focusable buttons
      if (this.activeModals.length > 0) {
        return this.activeModals.includes(this.parentView);
      }

      // No parent view (root), allow
      if (!this.parentView) {
        return true;
      }

      // Must belong to the current screen
      return this.parentView === this.currentScreen;
    },
    disabled() {
      return this.needsSession && !this.session;
    },
  },
  methods: {
    onClick(event) {
      if (!this.disabled) {
        this.$sounds.play('click');

        if (this.name) {
          this.$log.event({
            event_type: 'click',
            target: this.name,
            location: this.location,
            question_name: this.question,
          });
        }

        this.$emit('click', event);
      }
    },
  },
};
</script>
