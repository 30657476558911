<template functional>
  <div>
    <svg
      v-show="props.isLeft"
      :class="{
        hand: true,
        ['is-color-' + props.color]: true,
        'is-open': props.isOpen,
      }"
      viewBox="0 0 150 180"
    >
      <g class="hand--closed">
        <rect
          x="75.7183"
          y="1"
          width="8"
          height="102"
          fill="#C4C4C4"
          stroke="#304D63"
          stroke-width="2"
        />
        <path
          d="M85.6699 45.6973H86.6699V44.6973V7.71727V6.71727H85.6699H21.9999L20.3278 6.71727L21.1189 8.19038L30.8135 26.2429L21.1197 44.2227L20.3247 45.6973L21.9999 45.6973H85.6699Z"
          fill="#FF5959"
          stroke="#304D63"
          stroke-width="2"
        />
        <path
          d="M106.353 113.321C111.438 108.051 110.844 100.11 105.61 93.968L104.602 93.8381C106.159 91.8028 107.151 89.3925 107.477 86.851C107.803 84.3096 107.451 81.7271 106.458 79.365C106.787 77.492 107.478 75.567 106.895 73.7565C106.313 71.9461 105.299 70.3046 103.94 68.9741C94.1021 61.0695 84.3573 63.1663 74.5569 63.1477C66.0372 63.1477 48.4223 61.051 48.4223 61.051C48.3429 61.0327 48.2605 61.0327 48.1811 61.051C30.7519 63.9827 25.369 71.5904 20.506 81.4617C17.499 87.6778 14.4921 95.0998 8.47817 102.522C7.0675 104.563 5.74962 106.586 5.67538 106.715C2.36956 111.604 0.634192 117.385 0.700925 123.285C-0.0786556 135.123 9.98158 151.526 26.9653 150.933C31.0302 156.351 39.4386 163.253 45.1184 165.406C55.5514 169.116 66.8541 169.633 77.5824 166.89C83.8055 165.337 89.3673 161.832 93.4524 156.889C95.4997 156.748 97.5176 156.324 99.4478 155.627C111.438 150.914 109.935 141.97 106.872 134.456C112.719 129.241 111.717 119.222 106.353 113.321Z"
          fill="#C3F8FF"
          class="hand-color"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.8664 87.5356C55.8952 96.7399 57.3231 107.839 56.5535 118.991C56.5448 119.108 56.5595 119.226 56.5966 119.338C56.6338 119.45 56.6927 119.553 56.7701 119.642C56.8474 119.731 56.9417 119.803 57.0473 119.855C57.1529 119.907 57.2677 119.938 57.3853 119.946C57.6191 119.958 57.8486 119.878 58.024 119.723C58.1993 119.568 58.3064 119.35 58.3221 119.116C59.1195 107.533 57.5718 96.0168 50.292 86.3918C50.2205 86.2979 50.1312 86.2189 50.0292 86.1594C49.9272 86.1 49.8144 86.0612 49.6974 86.0453C49.5804 86.0294 49.4613 86.0367 49.3471 86.0668C49.2329 86.0968 49.1257 86.1491 49.0317 86.2206C48.9378 86.292 48.8588 86.3813 48.7993 86.4833C48.7398 86.5853 48.701 86.698 48.6851 86.815C48.6692 86.9319 48.6764 87.0509 48.7065 87.1651C48.7366 87.2792 48.7889 87.3863 48.8604 87.4803L48.8664 87.5356Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M76.7101 75.1715C75.7835 77.8479 75.2277 80.6386 75.0581 83.4657C75.0817 83.7003 75.1955 83.9166 75.3755 84.069C75.5556 84.2213 75.788 84.2977 76.0233 84.2821C76.1405 84.2728 76.2546 84.2403 76.3591 84.1867C76.4637 84.1331 76.5566 84.0593 76.6325 83.9696C76.7084 83.8799 76.7658 83.7761 76.8014 83.6641C76.837 83.5522 76.8501 83.4343 76.8399 83.3172C77.0108 80.786 77.5228 78.2894 78.362 75.8951C78.4472 75.6761 78.4424 75.4322 78.3485 75.2167C78.2546 75.0012 78.0794 74.8316 77.8609 74.7447C77.6515 74.65 77.4132 74.6417 77.1977 74.7216C76.9822 74.8015 76.807 74.9632 76.7101 75.1715Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69.4712 114.657C60.7028 109.117 62.3122 101.715 68.8401 96.9369C68.9434 96.8673 69.0282 96.7734 69.0867 96.6633C69.1452 96.5533 69.1759 96.4307 69.1759 96.3061C69.1759 96.1815 69.1452 96.0588 69.0867 95.9488C69.0282 95.8388 68.9434 95.7448 68.8401 95.6752C68.5986 95.5042 68.3101 95.4124 68.0142 95.4124C67.7183 95.4124 67.4297 95.5042 67.1882 95.6752C59.9307 101.446 58.6313 110.241 68.209 116.086C73.7284 118.546 79.7063 119.811 85.7496 119.797C93.0257 119.927 100.599 118.498 105.48 114.23C111.587 108.979 111.253 100.462 105.703 93.9495C105.493 93.7408 105.224 93.6008 104.932 93.5478C104.641 93.4948 104.34 93.5313 104.07 93.6527C103.953 93.6961 103.849 93.7675 103.766 93.8605C103.684 93.9535 103.625 94.0653 103.596 94.1862C103.567 94.307 103.568 94.4332 103.598 94.5537C103.629 94.6742 103.689 94.7853 103.773 94.8773C108.747 100.685 109.997 107.47 104.595 112.165C100.196 115.969 92.376 118.034 85.7125 117.904C80.1305 117.976 74.5962 116.869 69.4712 114.657ZM69.4712 114.657C68.225 117.12 70.9271 115.382 69.4712 114.676V114.657Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M105.56 132.816C99.3977 136.527 87.8653 138.055 79.1228 136.942C73.5544 136.218 68.6171 134.771 66.4825 132.711C61.4225 126.39 62.759 119.677 69.5266 116.011C69.6404 115.959 69.7391 115.879 69.8136 115.778C69.888 115.678 69.9358 115.56 69.9525 115.436C69.9691 115.312 69.9541 115.185 69.9088 115.069C69.8636 114.952 69.7895 114.849 69.6936 114.768C69.4777 114.567 69.2047 114.437 68.9122 114.398C68.6198 114.358 68.3221 114.409 68.0602 114.545C60.6356 118.795 59.2064 126.755 64.5706 133.527V133.62C66.965 135.958 72.3851 137.795 78.8073 138.612C88.1994 139.799 99.6518 138.908 106.241 134.901L105.369 135.068L106.352 134.808C112.775 129.52 111.791 119.147 106.222 113.061C106.016 112.852 105.749 112.713 105.46 112.663C105.17 112.614 104.872 112.655 104.608 112.783C104.491 112.829 104.387 112.904 104.306 113C104.225 113.096 104.169 113.211 104.143 113.334C104.117 113.458 104.122 113.585 104.158 113.706C104.194 113.827 104.259 113.937 104.348 114.026C109.378 119.611 111.221 128.066 105.56 132.816Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M64.7377 133.045C58.6496 138.834 60.5057 146.758 67.8375 152.955C67.9053 153.027 67.9875 153.084 68.0788 153.122C74.2871 155.801 80.9736 157.195 87.7354 157.223C91.6837 157.443 95.6392 156.946 99.4105 155.757C111.642 150.951 109.842 141.729 106.612 134.14C106.47 133.884 106.248 133.682 105.98 133.565C105.711 133.447 105.412 133.421 105.127 133.491C104.533 133.62 104.199 134.103 104.404 134.585C107.586 140.785 109.643 149.832 98.2411 154.161C94.8124 155.213 91.2224 155.64 87.6425 155.423C81.3742 155.411 75.1714 154.149 69.3967 151.712C62.759 146.542 60.7027 138.728 66.5753 134.233C66.6654 134.144 66.7329 134.034 66.7723 133.914C66.8117 133.793 66.8218 133.665 66.8019 133.54C66.7819 133.415 66.7325 133.297 66.6575 133.194C66.5826 133.092 66.4845 133.009 66.3712 132.952C66.123 132.796 65.8323 132.721 65.5396 132.738C65.2469 132.755 64.9666 132.862 64.7377 133.045Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M102.882 100.852C103.85 103.21 103.993 105.825 103.29 108.274C103.231 108.503 103.265 108.746 103.385 108.95C103.505 109.153 103.701 109.301 103.93 109.36C104.159 109.419 104.402 109.385 104.606 109.264C104.81 109.144 104.957 108.948 105.016 108.72C105.826 105.888 105.649 102.865 104.515 100.147C104.471 100.038 104.405 99.9397 104.321 99.8571C104.238 99.7744 104.138 99.7095 104.029 99.6662C103.92 99.6228 103.803 99.602 103.686 99.6049C103.568 99.6079 103.453 99.6345 103.346 99.6832C103.129 99.777 102.959 99.9527 102.872 100.172C102.785 100.391 102.788 100.636 102.882 100.852Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M103.995 120.966C104.826 123.258 104.943 125.749 104.329 128.109C104.276 128.323 104.305 128.55 104.41 128.744C104.515 128.939 104.688 129.087 104.896 129.161C105.104 129.235 105.332 129.229 105.536 129.145C105.74 129.06 105.905 128.902 106 128.703C106.747 126.004 106.643 123.14 105.703 120.502C105.673 120.389 105.62 120.282 105.549 120.189C105.477 120.096 105.388 120.018 105.287 119.959C105.185 119.9 105.072 119.862 104.956 119.847C104.839 119.832 104.721 119.84 104.608 119.871C104.382 119.936 104.191 120.088 104.076 120.293C103.961 120.498 103.932 120.74 103.995 120.966Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M102.417 141.209C102.886 143.665 102.416 146.208 101.099 148.334C100.975 148.533 100.934 148.774 100.986 149.004C101.038 149.233 101.179 149.432 101.378 149.558C101.476 149.623 101.586 149.667 101.701 149.687C101.817 149.708 101.935 149.706 102.05 149.68C102.164 149.654 102.272 149.605 102.367 149.536C102.462 149.468 102.542 149.38 102.603 149.28C104.157 146.793 104.714 143.811 104.162 140.931C104.144 140.815 104.103 140.704 104.042 140.604C103.981 140.504 103.901 140.417 103.806 140.348C103.711 140.279 103.603 140.23 103.489 140.202C103.375 140.175 103.257 140.17 103.141 140.189C103.026 140.207 102.915 140.248 102.816 140.309C102.717 140.37 102.631 140.451 102.563 140.546C102.496 140.641 102.448 140.749 102.423 140.863C102.398 140.977 102.396 141.095 102.417 141.209Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.9282 151.062C30.9932 156.481 39.4014 163.383 45.0812 165.536C55.5142 169.246 66.8169 169.763 77.5452 167.02C84.0932 165.43 89.9056 161.661 94.0277 156.332C94.4361 155.738 92.283 155.683 92.283 155.683C88.4199 160.508 83.0685 163.92 77.0627 165.387C66.7036 168.045 55.7866 167.547 45.7124 163.958C40.2739 161.917 32.2553 155.275 28.3574 150.098C28.2168 149.909 28.007 149.783 27.7739 149.748C27.5409 149.713 27.3035 149.772 27.1138 149.912C26.9464 150.047 26.8348 150.239 26.8005 150.452C26.7663 150.664 26.8118 150.882 26.9282 151.062Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M50.9095 89.8856C54.9228 90.8022 59.0911 90.8022 63.1044 89.8856C67.2162 89.1525 71.2722 88.1357 75.2435 86.8426C82.4083 90.9062 91.2992 92.7432 98.798 89.5702C102.974 87.8075 105.907 85.5437 106.984 81.109C107.62 78.9955 107.674 76.7497 107.142 74.6078C106.61 72.4658 105.51 70.5068 103.958 68.9368C94.1206 61.0322 84.3758 63.129 74.5754 63.1104C66.0557 63.1104 48.4408 61.0137 48.4408 61.0137C48.3614 60.9954 48.2789 60.9954 48.1995 61.0137C30.7703 63.9454 25.3876 71.5531 20.5245 81.4245C17.4989 87.6776 14.492 95.0997 8.4781 102.522C7.06743 104.563 5.74955 106.585 5.6753 106.715C2.36949 111.604 0.634119 117.385 0.700852 123.285C-0.208659 137.202 13.8052 157.464 36.5986 148.947C36.8201 148.863 36.9994 148.695 37.0969 148.479C37.1943 148.264 37.2021 148.018 37.1184 147.797C37.0347 147.575 36.8664 147.396 36.6506 147.299C36.4348 147.201 36.1891 147.193 35.9676 147.277C14.7147 155.2 1.62892 136.385 2.557 123.415C2.4692 117.821 4.10188 112.335 7.2344 107.699C7.2344 107.699 8.57092 105.658 10.0002 103.579C16.1069 95.9161 19.0767 88.5311 22.2692 82.0739C26.9096 72.7963 32.014 65.5041 48.5151 62.7022C50.1114 62.8878 66.5569 64.799 74.7239 64.799C84.1345 64.799 93.5266 62.6466 102.956 70.2171C104.266 71.57 105.188 73.25 105.625 75.0814C106.063 76.9127 106 78.8279 105.443 80.6266C104.496 84.5046 101.954 86.3045 98.2969 87.8631C94.1335 89.3734 89.6238 89.6567 85.3039 88.6796C82.0401 87.9199 78.9107 86.6686 76.0232 84.9685C75.9058 84.9185 75.7795 84.8927 75.652 84.8927C75.5244 84.8927 75.3981 84.9185 75.2807 84.9685C71.2817 86.2936 67.1942 87.3353 63.0487 88.0858C59.3212 88.9251 55.4537 88.9251 51.7262 88.0858C51.5022 88.0095 51.2571 88.0253 51.0448 88.1297C50.8325 88.2341 50.6703 88.4185 50.594 88.6424C50.5177 88.8664 50.5334 89.1114 50.6379 89.3237C50.7423 89.5359 50.9268 89.698 51.1508 89.7743L50.9095 89.8856Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M102.306 93.9309C96.1436 97.6419 85.4337 98.347 76.6912 97.2337C71.1228 96.5101 66.1855 95.0627 64.0509 93.0031C63.0947 91.8075 62.3479 90.4586 61.8421 89.0137L59.5219 89.1807C60.1126 90.898 60.9982 92.4992 62.139 93.9123C64.5334 96.2688 69.9534 98.0873 76.3756 98.9037C85.6563 100.091 97.2202 99.2006 103.809 95.1926L103.921 95.0999C108.82 90.1996 109.231 83.2081 106.445 77.2126C105.74 75.821 105.796 82.037 105.796 82.037C106.368 84.1484 106.345 86.3769 105.729 88.4761C105.113 90.5752 103.929 92.4631 102.306 93.9309Z"
          fill="#304D63"
        />
      </g>
      <g class="hand--open">
        <rect
          x="75.7183"
          y="1"
          width="8"
          height="102"
          fill="#C4C4C4"
          stroke="#304D63"
          stroke-width="2"
        />
        <path
          d="M85.6699 45.6973H86.6699V44.6973V7.71727V6.71727H85.6699H21.9999L20.3278 6.71727L21.1189 8.19038L30.8135 26.2429L21.1197 44.2227L20.3247 45.6973L21.9999 45.6973H85.6699Z"
          fill="#FF5959"
          stroke="#304D63"
          stroke-width="2"
        />
        <path
          d="M106.353 113.321C111.438 108.051 110.844 100.11 105.61 93.968L104.602 93.8381C106.159 91.8028 107.151 89.3925 107.477 86.851C107.803 84.3096 107.451 81.7271 106.458 79.365C106.787 77.492 107.478 75.567 106.895 73.7565C106.313 71.9461 105.299 70.3046 103.94 68.9741C94.1021 61.0695 84.3573 63.1663 74.5569 63.1477C66.0372 63.1477 48.4223 61.051 48.4223 61.051C48.3429 61.0327 48.2605 61.0327 48.1811 61.051C30.7519 63.9827 25.369 71.5904 20.506 81.4617C17.499 87.6778 14.4921 95.0998 8.47817 102.522C7.0675 104.563 5.74962 106.586 5.67538 106.715C2.36956 111.604 0.634192 117.385 0.700925 123.285C-0.0786556 135.123 9.98158 151.526 26.9653 150.933C31.0302 156.351 39.4386 163.253 45.1184 165.406C55.5514 169.116 66.8541 169.633 77.5824 166.89C83.8055 165.337 89.3673 161.832 93.4524 156.889C95.4997 156.748 97.5176 156.324 99.4478 155.627C111.438 150.914 109.935 141.97 106.872 134.456C112.719 129.241 111.717 119.222 106.353 113.321Z"
          fill="#C3F8FF"
          class="hand-color"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.8664 87.5356C55.8952 96.7399 57.3231 107.839 56.5535 118.991C56.5448 119.108 56.5595 119.226 56.5966 119.338C56.6338 119.45 56.6927 119.553 56.7701 119.642C56.8474 119.731 56.9417 119.803 57.0473 119.855C57.1529 119.907 57.2677 119.938 57.3853 119.946C57.6191 119.958 57.8486 119.878 58.024 119.723C58.1993 119.568 58.3064 119.35 58.3221 119.116C59.1195 107.533 57.5718 96.0168 50.292 86.3918C50.2205 86.2979 50.1312 86.2189 50.0292 86.1594C49.9272 86.1 49.8144 86.0612 49.6974 86.0453C49.5804 86.0294 49.4613 86.0367 49.3471 86.0668C49.2329 86.0968 49.1257 86.1491 49.0317 86.2206C48.9378 86.292 48.8588 86.3813 48.7993 86.4833C48.7398 86.5853 48.701 86.698 48.6851 86.815C48.6692 86.9319 48.6764 87.0509 48.7065 87.1651C48.7366 87.2792 48.7889 87.3863 48.8604 87.4803L48.8664 87.5356Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M76.7101 75.1715C75.7835 77.8479 75.2277 80.6386 75.0581 83.4657C75.0817 83.7003 75.1955 83.9166 75.3755 84.069C75.5556 84.2213 75.788 84.2977 76.0233 84.2821C76.1405 84.2728 76.2546 84.2403 76.3591 84.1867C76.4637 84.1331 76.5566 84.0593 76.6325 83.9696C76.7084 83.8799 76.7658 83.7761 76.8014 83.6641C76.837 83.5522 76.8501 83.4343 76.8399 83.3172C77.0108 80.786 77.5228 78.2894 78.362 75.8951C78.4472 75.6761 78.4424 75.4322 78.3485 75.2167C78.2546 75.0012 78.0794 74.8316 77.8609 74.7447C77.6515 74.65 77.4132 74.6417 77.1977 74.7216C76.9822 74.8015 76.807 74.9632 76.7101 75.1715Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69.4712 114.657C60.7028 109.117 62.3122 101.715 68.8401 96.9369C68.9434 96.8673 69.0282 96.7734 69.0867 96.6633C69.1452 96.5533 69.1759 96.4307 69.1759 96.3061C69.1759 96.1815 69.1452 96.0588 69.0867 95.9488C69.0282 95.8388 68.9434 95.7448 68.8401 95.6752C68.5986 95.5042 68.3101 95.4124 68.0142 95.4124C67.7183 95.4124 67.4297 95.5042 67.1882 95.6752C59.9307 101.446 58.6313 110.241 68.209 116.086C73.7284 118.546 79.7063 119.811 85.7496 119.797C93.0257 119.927 100.599 118.498 105.48 114.23C111.587 108.979 111.253 100.462 105.703 93.9495C105.493 93.7408 105.224 93.6008 104.932 93.5478C104.641 93.4948 104.34 93.5313 104.07 93.6527C103.953 93.6961 103.849 93.7675 103.766 93.8605C103.684 93.9535 103.625 94.0653 103.596 94.1862C103.567 94.307 103.568 94.4332 103.598 94.5537C103.629 94.6742 103.689 94.7853 103.773 94.8773C108.747 100.685 109.997 107.47 104.595 112.165C100.196 115.969 92.376 118.034 85.7125 117.904C80.1305 117.976 74.5962 116.869 69.4712 114.657ZM69.4712 114.657C68.225 117.12 70.9271 115.382 69.4712 114.676V114.657Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M105.56 132.816C99.3977 136.527 87.8653 138.055 79.1228 136.942C73.5544 136.218 68.6171 134.771 66.4825 132.711C61.4225 126.39 62.759 119.677 69.5266 116.011C69.6404 115.959 69.7391 115.879 69.8136 115.778C69.888 115.678 69.9358 115.56 69.9525 115.436C69.9691 115.312 69.9541 115.185 69.9088 115.069C69.8636 114.952 69.7895 114.849 69.6936 114.768C69.4777 114.567 69.2047 114.437 68.9122 114.398C68.6198 114.358 68.3221 114.409 68.0602 114.545C60.6356 118.795 59.2064 126.755 64.5706 133.527V133.62C66.965 135.958 72.3851 137.795 78.8073 138.612C88.1994 139.799 99.6518 138.908 106.241 134.901L105.369 135.068L106.352 134.808C112.775 129.52 111.791 119.147 106.222 113.061C106.016 112.852 105.749 112.713 105.46 112.663C105.17 112.614 104.872 112.655 104.608 112.783C104.491 112.829 104.387 112.904 104.306 113C104.225 113.096 104.169 113.211 104.143 113.334C104.117 113.458 104.122 113.585 104.158 113.706C104.194 113.827 104.259 113.937 104.348 114.026C109.378 119.611 111.221 128.066 105.56 132.816Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M64.7377 133.045C58.6496 138.834 60.5057 146.758 67.8375 152.955C67.9053 153.027 67.9875 153.084 68.0788 153.122C74.2871 155.801 80.9736 157.195 87.7354 157.223C91.6837 157.443 95.6392 156.946 99.4105 155.757C111.642 150.951 109.842 141.729 106.612 134.14C106.47 133.884 106.248 133.682 105.98 133.565C105.711 133.447 105.412 133.421 105.127 133.491C104.533 133.62 104.199 134.103 104.404 134.585C107.586 140.785 109.643 149.832 98.2411 154.161C94.8124 155.213 91.2224 155.64 87.6425 155.423C81.3742 155.411 75.1714 154.149 69.3967 151.712C62.759 146.542 60.7027 138.728 66.5753 134.233C66.6654 134.144 66.7329 134.034 66.7723 133.914C66.8117 133.793 66.8218 133.665 66.8019 133.54C66.7819 133.415 66.7325 133.297 66.6575 133.194C66.5826 133.092 66.4845 133.009 66.3712 132.952C66.123 132.796 65.8323 132.721 65.5396 132.738C65.2469 132.755 64.9666 132.862 64.7377 133.045Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M102.882 100.852C103.85 103.21 103.993 105.825 103.29 108.274C103.231 108.503 103.265 108.746 103.385 108.95C103.505 109.153 103.701 109.301 103.93 109.36C104.159 109.419 104.402 109.385 104.606 109.264C104.81 109.144 104.957 108.948 105.016 108.72C105.826 105.888 105.649 102.865 104.515 100.147C104.471 100.038 104.405 99.9397 104.321 99.8571C104.238 99.7744 104.138 99.7095 104.029 99.6662C103.92 99.6228 103.803 99.602 103.686 99.6049C103.568 99.6079 103.453 99.6345 103.346 99.6832C103.129 99.777 102.959 99.9527 102.872 100.172C102.785 100.391 102.788 100.636 102.882 100.852Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M103.995 120.966C104.826 123.258 104.943 125.749 104.329 128.109C104.276 128.323 104.305 128.55 104.41 128.744C104.515 128.939 104.688 129.087 104.896 129.161C105.104 129.235 105.332 129.229 105.536 129.145C105.74 129.06 105.905 128.902 106 128.703C106.747 126.004 106.643 123.14 105.703 120.502C105.673 120.389 105.62 120.282 105.549 120.189C105.477 120.096 105.388 120.018 105.287 119.959C105.185 119.9 105.072 119.862 104.956 119.847C104.839 119.832 104.721 119.84 104.608 119.871C104.382 119.936 104.191 120.088 104.076 120.293C103.961 120.498 103.932 120.74 103.995 120.966Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M102.417 141.209C102.886 143.665 102.416 146.208 101.099 148.334C100.975 148.533 100.934 148.774 100.986 149.004C101.038 149.233 101.179 149.432 101.378 149.558C101.476 149.623 101.586 149.667 101.701 149.687C101.817 149.708 101.935 149.706 102.05 149.68C102.164 149.654 102.272 149.605 102.367 149.536C102.462 149.468 102.542 149.38 102.603 149.28C104.157 146.793 104.714 143.811 104.162 140.931C104.144 140.815 104.103 140.704 104.042 140.604C103.981 140.504 103.901 140.417 103.806 140.348C103.711 140.279 103.603 140.23 103.489 140.202C103.375 140.175 103.257 140.17 103.141 140.189C103.026 140.207 102.915 140.248 102.816 140.309C102.717 140.37 102.631 140.451 102.563 140.546C102.496 140.641 102.448 140.749 102.423 140.863C102.398 140.977 102.396 141.095 102.417 141.209Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.9282 151.062C30.9932 156.481 39.4014 163.383 45.0812 165.536C55.5142 169.246 66.8169 169.763 77.5452 167.02C84.0932 165.43 89.9056 161.661 94.0277 156.332C94.4361 155.738 92.283 155.683 92.283 155.683C88.4199 160.508 83.0685 163.92 77.0627 165.387C66.7036 168.045 55.7866 167.547 45.7124 163.958C40.2739 161.917 32.2553 155.275 28.3574 150.098C28.2168 149.909 28.007 149.783 27.7739 149.748C27.5409 149.713 27.3035 149.772 27.1138 149.912C26.9464 150.047 26.8348 150.239 26.8005 150.452C26.7663 150.664 26.8118 150.882 26.9282 151.062Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M50.9095 89.8856C54.9228 90.8022 59.0911 90.8022 63.1044 89.8856C67.2162 89.1525 71.2722 88.1357 75.2435 86.8426C82.4083 90.9062 91.2992 92.7432 98.798 89.5702C102.974 87.8075 105.907 85.5437 106.984 81.109C107.62 78.9955 107.674 76.7497 107.142 74.6078C106.61 72.4658 105.51 70.5068 103.958 68.9368C94.1206 61.0322 84.3758 63.129 74.5754 63.1104C66.0557 63.1104 48.4408 61.0137 48.4408 61.0137C48.3614 60.9954 48.2789 60.9954 48.1995 61.0137C30.7703 63.9454 25.3876 71.5531 20.5245 81.4245C17.4989 87.6776 14.492 95.0997 8.4781 102.522C7.06743 104.563 5.74955 106.585 5.6753 106.715C2.36949 111.604 0.634119 117.385 0.700852 123.285C-0.208659 137.202 13.8052 157.464 36.5986 148.947C36.8201 148.863 36.9994 148.695 37.0969 148.479C37.1943 148.264 37.2021 148.018 37.1184 147.797C37.0347 147.575 36.8664 147.396 36.6506 147.299C36.4348 147.201 36.1891 147.193 35.9676 147.277C14.7147 155.2 1.62892 136.385 2.557 123.415C2.4692 117.821 4.10188 112.335 7.2344 107.699C7.2344 107.699 8.57092 105.658 10.0002 103.579C16.1069 95.9161 19.0767 88.5311 22.2692 82.0739C26.9096 72.7963 32.014 65.5041 48.5151 62.7022C50.1114 62.8878 66.5569 64.799 74.7239 64.799C84.1345 64.799 93.5266 62.6466 102.956 70.2171C104.266 71.57 105.188 73.25 105.625 75.0814C106.063 76.9127 106 78.8279 105.443 80.6266C104.496 84.5046 101.954 86.3045 98.2969 87.8631C94.1335 89.3734 89.6238 89.6567 85.3039 88.6796C82.0401 87.9199 78.9107 86.6686 76.0232 84.9685C75.9058 84.9185 75.7795 84.8927 75.652 84.8927C75.5244 84.8927 75.3981 84.9185 75.2807 84.9685C71.2817 86.2936 67.1942 87.3353 63.0487 88.0858C59.3212 88.9251 55.4537 88.9251 51.7262 88.0858C51.5022 88.0095 51.2571 88.0253 51.0448 88.1297C50.8325 88.2341 50.6703 88.4185 50.594 88.6424C50.5177 88.8664 50.5334 89.1114 50.6379 89.3237C50.7423 89.5359 50.9268 89.698 51.1508 89.7743L50.9095 89.8856Z"
          fill="#304D63"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M102.306 93.9309C96.1436 97.6419 85.4337 98.347 76.6912 97.2337C71.1228 96.5101 66.1855 95.0627 64.0509 93.0031C63.0947 91.8075 62.3479 90.4586 61.8421 89.0137L59.5219 89.1807C60.1126 90.898 60.9982 92.4992 62.139 93.9123C64.5334 96.2688 69.9534 98.0873 76.3756 98.9037C85.6563 100.091 97.2202 99.2006 103.809 95.1926L103.921 95.0999C108.82 90.1996 109.231 83.2081 106.445 77.2126C105.74 75.821 105.796 82.037 105.796 82.037C106.368 84.1484 106.345 86.3769 105.729 88.4761C105.113 90.5752 103.929 92.4631 102.306 93.9309Z"
          fill="#304D63"
        />
      </g>
    </svg>
    <svg
      v-show="props.isLeft == false"
      :class="{
        hand: true,
        ['is-color-' + props.color]: true,
        'is-open': props.isOpen,
      }"
      viewBox="0 0 150 180"
    >
      <g class="hand--closed">
        <svg
          width="111"
          height="169"
          viewBox="0 0 111 169"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="-1"
            width="8"
            height="102"
            transform="matrix(-1 0 0 1 36.1177 2)"
            fill="#C4C4C4"
            stroke="#304D63"
            stroke-width="2"
          />
          <path
            d="M25 45.6973H24V44.6973L24 7.71727V6.71727H25H88.67L90.3421 6.71727L89.551 8.19038L79.8564 26.2429L89.5502 44.2227L90.3452 45.6973L88.67 45.6973H25Z"
            fill="#6AEA9C"
            stroke="#304D63"
            stroke-width="2"
          />
          <path
            d="M4.48332 113.321C-0.602509 108.051 -0.00849915 100.11 5.22583 93.968L6.23392 93.8381C4.67693 91.8028 3.68523 89.3925 3.3593 86.851C3.03336 84.3096 3.38474 81.7271 4.37777 79.365C4.04866 77.492 3.35831 75.567 3.94044 73.7565C4.52258 71.9461 5.53712 70.3046 6.89629 68.9741C16.7339 61.0695 26.4786 63.1663 36.2791 63.1477C44.7988 63.1477 62.4136 61.051 62.4136 61.051C62.493 61.0327 62.5754 61.0327 62.6548 61.051C80.084 63.9827 85.4669 71.5904 90.33 81.4617C93.3369 87.6778 96.3439 95.0998 102.358 102.522C103.768 104.563 105.086 106.586 105.161 106.715C108.466 111.604 110.202 117.385 110.135 123.285C110.915 135.123 100.854 151.526 83.8706 150.933C79.8057 156.351 71.3973 163.253 65.7175 165.406C55.2846 169.116 43.9818 169.633 33.2536 166.89C27.0304 165.337 21.4687 161.832 17.3835 156.889C15.3362 156.748 13.3184 156.324 11.3881 155.627C-0.602562 150.914 0.900909 141.97 3.96355 134.456C-1.8833 129.241 -0.880936 119.222 4.48332 113.321Z"
            fill="#C3F8FF"
            class="hand-color"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M61.9696 87.5356C54.9407 96.7399 53.5129 107.839 54.2824 118.991C54.2912 119.108 54.2765 119.226 54.2393 119.338C54.2021 119.45 54.1432 119.553 54.0658 119.642C53.9885 119.731 53.8943 119.803 53.7887 119.855C53.6831 119.907 53.5682 119.938 53.4507 119.946C53.2168 119.958 52.9873 119.878 52.812 119.723C52.6366 119.568 52.5296 119.35 52.5138 119.116C51.7165 107.533 53.2641 96.0168 60.544 86.3918C60.6155 86.2979 60.7047 86.2189 60.8068 86.1594C60.9088 86.1 61.0215 86.0612 61.1386 86.0453C61.2556 86.0294 61.3746 86.0367 61.4888 86.0668C61.603 86.0968 61.7102 86.1491 61.8042 86.2206C61.8982 86.292 61.9772 86.3813 62.0366 86.4833C62.0961 86.5853 62.1349 86.698 62.1509 86.815C62.1668 86.9319 62.1595 87.0509 62.1294 87.1651C62.0993 87.2792 62.0471 87.3863 61.9756 87.4803L61.9696 87.5356Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34.1258 75.1715C35.0524 77.8479 35.6082 80.6386 35.7778 83.4657C35.7543 83.7003 35.6405 83.9166 35.4604 84.069C35.2803 84.2213 35.048 84.2977 34.8126 84.2821C34.6955 84.2728 34.5814 84.2403 34.4768 84.1867C34.3722 84.1331 34.2794 84.0593 34.2034 83.9696C34.1275 83.8799 34.0701 83.7761 34.0345 83.6641C33.9989 83.5522 33.9858 83.4343 33.996 83.3172C33.8251 80.786 33.3131 78.2894 32.474 75.8951C32.3887 75.6761 32.3936 75.4322 32.4874 75.2167C32.5813 75.0012 32.7566 74.8316 32.975 74.7447C33.1844 74.65 33.4228 74.6417 33.6382 74.7216C33.8537 74.8015 34.0289 74.9632 34.1258 75.1715Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M41.3647 114.657C50.1331 109.117 48.5237 101.715 41.9959 96.9369C41.8925 96.8673 41.8078 96.7734 41.7492 96.6633C41.6907 96.5533 41.6601 96.4307 41.6601 96.3061C41.6601 96.1815 41.6907 96.0588 41.7492 95.9488C41.8078 95.8388 41.8925 95.7448 41.9959 95.6752C42.2373 95.5042 42.5258 95.4124 42.8217 95.4124C43.1176 95.4124 43.4063 95.5042 43.6477 95.6752C50.9053 101.446 52.2046 110.241 42.6269 116.086C37.1075 118.546 31.1296 119.811 25.0863 119.797C17.8102 119.927 10.2372 118.498 5.35549 114.23C-0.751221 108.979 -0.417107 100.462 5.13277 93.9495C5.34312 93.7408 5.61189 93.6008 5.90348 93.5478C6.19506 93.4948 6.4958 93.5313 6.76618 93.6527C6.88272 93.6961 6.98689 93.7675 7.06946 93.8605C7.15202 93.9535 7.21054 94.0653 7.23985 94.1862C7.26915 94.307 7.26837 94.4332 7.23758 94.5537C7.20679 94.6742 7.14692 94.7853 7.06322 94.8773C2.08876 100.685 0.839325 107.47 6.2407 112.165C10.6398 115.969 18.4599 118.034 25.1235 117.904C30.7054 117.976 36.2397 116.869 41.3647 114.657ZM41.3647 114.657C42.6109 117.12 39.9089 115.382 41.3647 114.676V114.657Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.27582 132.816C11.4382 136.527 22.9707 138.055 31.7131 136.942C37.2815 136.218 42.2189 134.771 44.3534 132.711C49.4134 126.39 48.0769 119.677 41.3093 116.011C41.1955 115.959 41.0968 115.879 41.0224 115.778C40.9479 115.678 40.9002 115.56 40.8835 115.436C40.8668 115.312 40.8818 115.185 40.9271 115.069C40.9724 114.952 41.0464 114.849 41.1423 114.768C41.3583 114.567 41.6312 114.437 41.9237 114.398C42.2162 114.358 42.5138 114.409 42.7758 114.545C50.2003 118.795 51.6296 126.755 46.2653 133.527V133.62C43.8709 135.958 38.4509 137.795 32.0286 138.612C22.6365 139.799 11.1842 138.908 4.59487 134.901L5.46721 135.068L4.48351 134.808C-1.93875 129.52 -0.954979 119.147 4.61345 113.061C4.82012 112.852 5.08674 112.713 5.37624 112.663C5.66573 112.614 5.96364 112.655 6.22829 112.783C6.34534 112.829 6.449 112.904 6.53009 113C6.61119 113.096 6.66714 113.211 6.69289 113.334C6.71864 113.458 6.71345 113.585 6.67771 113.706C6.64197 113.827 6.57687 113.937 6.48817 114.026C1.45802 119.611 -0.385414 128.066 5.27582 132.816Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M46.0982 133.045C52.1864 138.834 50.3302 146.758 42.9985 152.955C42.9306 153.027 42.8484 153.084 42.7572 153.122C36.5488 155.801 29.8624 157.195 23.1006 157.223C19.1522 157.443 15.1967 156.946 11.4255 155.757C-0.806526 150.951 0.993923 141.729 4.22361 134.14C4.36576 133.884 4.58806 133.682 4.85634 133.565C5.12462 133.447 5.42401 133.421 5.70851 133.491C6.30247 133.62 6.6366 134.103 6.43243 134.585C3.24953 140.785 1.19323 149.832 12.5948 154.161C16.0236 155.213 19.6135 155.64 23.1935 155.423C29.4617 155.411 35.6646 154.149 41.4393 151.712C48.0769 146.542 50.1332 138.728 44.2606 134.233C44.1706 134.144 44.103 134.034 44.0636 133.914C44.0242 133.793 44.0141 133.665 44.0341 133.54C44.054 133.415 44.1035 133.297 44.1784 133.194C44.2533 133.092 44.3515 133.009 44.4648 132.952C44.7129 132.796 45.0036 132.721 45.2963 132.738C45.5891 132.755 45.8694 132.862 46.0982 133.045Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.95434 100.852C6.98627 103.21 6.84245 105.825 7.54604 108.274C7.60511 108.503 7.57074 108.746 7.45065 108.95C7.33056 109.153 7.13452 109.301 6.90561 109.36C6.6767 109.419 6.4337 109.385 6.23007 109.264C6.02643 109.144 5.8788 108.948 5.81973 108.72C5.00973 105.888 5.18648 102.865 6.32092 100.147C6.36534 100.038 6.43127 99.9397 6.51476 99.8571C6.59825 99.7744 6.69762 99.7095 6.80682 99.6662C6.91603 99.6228 7.03287 99.602 7.15032 99.6049C7.26777 99.6079 7.38339 99.6345 7.4903 99.6832C7.70666 99.777 7.87698 99.9527 7.96397 100.172C8.05095 100.391 8.0475 100.636 7.95434 100.852Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.84082 120.966C6.00955 123.258 5.89296 125.749 6.50662 128.109C6.55945 128.323 6.53076 128.55 6.42607 128.744C6.32137 128.939 6.14817 129.087 5.94005 129.161C5.73193 129.235 5.50372 129.229 5.29973 129.145C5.09575 129.06 4.93057 128.902 4.83615 128.703C4.08926 126.004 4.19291 123.14 5.13308 120.502C5.16322 120.389 5.2156 120.282 5.28705 120.189C5.35849 120.096 5.44764 120.018 5.54943 119.959C5.65121 119.9 5.7635 119.862 5.88001 119.847C5.99651 119.832 6.11491 119.84 6.22826 119.871C6.45416 119.936 6.64532 120.088 6.76005 120.293C6.87477 120.498 6.9038 120.74 6.84082 120.966Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.4186 141.209C7.94969 143.665 8.42002 146.208 9.7365 148.334C9.86133 148.533 9.90207 148.774 9.8499 149.004C9.79773 149.233 9.65686 149.432 9.45803 149.558C9.35996 149.623 9.24989 149.667 9.13448 149.687C9.01907 149.708 8.90069 149.706 8.78634 149.68C8.67198 149.654 8.56409 149.605 8.46901 149.536C8.37394 149.468 8.29367 149.38 8.23303 149.28C6.67918 146.793 6.12227 143.811 6.67382 140.931C6.6919 140.815 6.73266 140.704 6.7938 140.604C6.85493 140.504 6.93527 140.417 7.03012 140.348C7.12496 140.279 7.23249 140.23 7.34654 140.202C7.46059 140.175 7.57887 140.17 7.69468 140.189C7.80998 140.207 7.92058 140.248 8.01982 140.309C8.11905 140.37 8.20496 140.451 8.27245 140.546C8.33995 140.641 8.3876 140.749 8.41271 140.863C8.43781 140.977 8.43984 141.095 8.4186 141.209Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M83.9077 151.062C79.8428 156.481 71.4345 163.383 65.7547 165.536C55.3217 169.246 44.019 169.763 33.2907 167.02C26.7427 165.43 20.9303 161.661 16.8082 156.332C16.3998 155.738 18.553 155.683 18.553 155.683C22.416 160.508 27.7674 163.92 33.7732 165.387C44.1323 168.045 55.0494 167.547 65.1235 163.958C70.562 161.917 78.5807 155.275 82.4786 150.098C82.6192 149.909 82.8289 149.783 83.062 149.748C83.2951 149.713 83.5324 149.772 83.7221 149.912C83.8895 150.047 84.0011 150.239 84.0354 150.452C84.0696 150.664 84.0241 150.882 83.9077 151.062Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M59.9264 89.8856C55.9131 90.8022 51.7448 90.8022 47.7315 89.8856C43.6197 89.1525 39.5637 88.1357 35.5924 86.8426C28.4277 90.9062 19.5367 92.7432 12.0379 89.5702C7.86158 87.8075 4.92881 85.5437 3.85224 81.109C3.21613 78.9955 3.16146 76.7497 3.69386 74.6078C4.22626 72.4658 5.32607 70.5068 6.87777 68.9368C16.7153 61.0322 26.4601 63.129 36.2606 63.1104C44.7803 63.1104 62.3951 61.0137 62.3951 61.0137C62.4745 60.9954 62.557 60.9954 62.6364 61.0137C80.0656 63.9454 85.4484 71.5531 90.3115 81.4245C93.337 87.6776 96.3439 95.0997 102.358 102.522C103.768 104.563 105.086 106.585 105.161 106.715C108.466 111.604 110.202 117.385 110.135 123.285C111.045 137.202 97.0308 157.464 74.2373 148.947C74.0158 148.863 73.8365 148.695 73.7391 148.479C73.6416 148.264 73.6338 148.018 73.7175 147.797C73.8012 147.575 73.9695 147.396 74.1853 147.299C74.4011 147.201 74.6468 147.193 74.8683 147.277C96.1212 155.2 109.207 136.385 108.279 123.415C108.367 117.821 106.734 112.335 103.602 107.699C103.602 107.699 102.265 105.658 100.836 103.579C94.7291 95.9161 91.7593 88.5311 88.5667 82.0739C83.9263 72.7963 78.8219 65.5041 62.3208 62.7022C60.7245 62.8878 44.2791 64.799 36.112 64.799C26.7014 64.799 17.3094 62.6466 7.88016 70.2171C6.57023 71.57 5.64816 73.25 5.21059 75.0814C4.77302 76.9127 4.83598 78.8279 5.39288 80.6266C6.33951 84.5046 8.88238 86.3045 12.539 87.8631C16.7024 89.3734 21.2121 89.6567 25.532 88.6796C28.7958 87.9199 31.9252 86.6686 34.8127 84.9685C34.9301 84.9185 35.0564 84.8927 35.184 84.8927C35.3116 84.8927 35.4379 84.9185 35.5552 84.9685C39.5542 86.2936 43.6417 87.3353 47.7873 88.0858C51.5147 88.9251 55.3822 88.9251 59.1097 88.0858C59.3337 88.0095 59.5788 88.0253 59.7911 88.1297C60.0035 88.2341 60.1656 88.4185 60.2419 88.6424C60.3182 88.8664 60.3025 89.1114 60.1981 89.3237C60.0936 89.5359 59.9091 89.698 59.6851 89.7743L59.9264 89.8856Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.52994 93.9309C14.6923 97.6419 25.4023 98.347 34.1447 97.2337C39.7131 96.5101 44.6505 95.0627 46.785 93.0031C47.7412 91.8075 48.488 90.4586 48.9938 89.0137L51.314 89.1807C50.7233 90.898 49.8377 92.4992 48.6969 93.9123C46.3025 96.2688 40.8826 98.0873 34.4603 98.9037C25.1796 100.091 13.6158 99.2006 7.02647 95.1926L6.9151 95.0999C2.01577 90.1996 1.60451 83.2081 4.39077 77.2126C5.09611 75.821 5.04038 82.037 5.04038 82.037C4.46784 84.1484 4.49078 86.3769 5.10665 88.4761C5.72252 90.5752 6.90732 92.4631 8.52994 93.9309Z"
            fill="#304D63"
          />
        </svg>
      </g>
      <g class="hand--open">
        <svg
          width="111"
          height="169"
          viewBox="0 0 111 169"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="-1"
            width="8"
            height="102"
            transform="matrix(-1 0 0 1 36.1177 2)"
            fill="#C4C4C4"
            stroke="#304D63"
            stroke-width="2"
          />
          <path
            d="M25 45.6973H24V44.6973L24 7.71727V6.71727H25H88.67L90.3421 6.71727L89.551 8.19038L79.8564 26.2429L89.5502 44.2227L90.3452 45.6973L88.67 45.6973H25Z"
            fill="#6AEA9C"
            stroke="#304D63"
            stroke-width="2"
          />
          <path
            d="M4.48332 113.321C-0.602509 108.051 -0.00849915 100.11 5.22583 93.968L6.23392 93.8381C4.67693 91.8028 3.68523 89.3925 3.3593 86.851C3.03336 84.3096 3.38474 81.7271 4.37777 79.365C4.04866 77.492 3.35831 75.567 3.94044 73.7565C4.52258 71.9461 5.53712 70.3046 6.89629 68.9741C16.7339 61.0695 26.4786 63.1663 36.2791 63.1477C44.7988 63.1477 62.4136 61.051 62.4136 61.051C62.493 61.0327 62.5754 61.0327 62.6548 61.051C80.084 63.9827 85.4669 71.5904 90.33 81.4617C93.3369 87.6778 96.3439 95.0998 102.358 102.522C103.768 104.563 105.086 106.586 105.161 106.715C108.466 111.604 110.202 117.385 110.135 123.285C110.915 135.123 100.854 151.526 83.8706 150.933C79.8057 156.351 71.3973 163.253 65.7175 165.406C55.2846 169.116 43.9818 169.633 33.2536 166.89C27.0304 165.337 21.4687 161.832 17.3835 156.889C15.3362 156.748 13.3184 156.324 11.3881 155.627C-0.602562 150.914 0.900909 141.97 3.96355 134.456C-1.8833 129.241 -0.880936 119.222 4.48332 113.321Z"
            fill="#C3F8FF"
            class="hand-color"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M61.9696 87.5356C54.9407 96.7399 53.5129 107.839 54.2824 118.991C54.2912 119.108 54.2765 119.226 54.2393 119.338C54.2021 119.45 54.1432 119.553 54.0658 119.642C53.9885 119.731 53.8943 119.803 53.7887 119.855C53.6831 119.907 53.5682 119.938 53.4507 119.946C53.2168 119.958 52.9873 119.878 52.812 119.723C52.6366 119.568 52.5296 119.35 52.5138 119.116C51.7165 107.533 53.2641 96.0168 60.544 86.3918C60.6155 86.2979 60.7047 86.2189 60.8068 86.1594C60.9088 86.1 61.0215 86.0612 61.1386 86.0453C61.2556 86.0294 61.3746 86.0367 61.4888 86.0668C61.603 86.0968 61.7102 86.1491 61.8042 86.2206C61.8982 86.292 61.9772 86.3813 62.0366 86.4833C62.0961 86.5853 62.1349 86.698 62.1509 86.815C62.1668 86.9319 62.1595 87.0509 62.1294 87.1651C62.0993 87.2792 62.0471 87.3863 61.9756 87.4803L61.9696 87.5356Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34.1258 75.1715C35.0524 77.8479 35.6082 80.6386 35.7778 83.4657C35.7543 83.7003 35.6405 83.9166 35.4604 84.069C35.2803 84.2213 35.048 84.2977 34.8126 84.2821C34.6955 84.2728 34.5814 84.2403 34.4768 84.1867C34.3722 84.1331 34.2794 84.0593 34.2034 83.9696C34.1275 83.8799 34.0701 83.7761 34.0345 83.6641C33.9989 83.5522 33.9858 83.4343 33.996 83.3172C33.8251 80.786 33.3131 78.2894 32.474 75.8951C32.3887 75.6761 32.3936 75.4322 32.4874 75.2167C32.5813 75.0012 32.7566 74.8316 32.975 74.7447C33.1844 74.65 33.4228 74.6417 33.6382 74.7216C33.8537 74.8015 34.0289 74.9632 34.1258 75.1715Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M41.3647 114.657C50.1331 109.117 48.5237 101.715 41.9959 96.9369C41.8925 96.8673 41.8078 96.7734 41.7492 96.6633C41.6907 96.5533 41.6601 96.4307 41.6601 96.3061C41.6601 96.1815 41.6907 96.0588 41.7492 95.9488C41.8078 95.8388 41.8925 95.7448 41.9959 95.6752C42.2373 95.5042 42.5258 95.4124 42.8217 95.4124C43.1176 95.4124 43.4063 95.5042 43.6477 95.6752C50.9053 101.446 52.2046 110.241 42.6269 116.086C37.1075 118.546 31.1296 119.811 25.0863 119.797C17.8102 119.927 10.2372 118.498 5.35549 114.23C-0.751221 108.979 -0.417107 100.462 5.13277 93.9495C5.34312 93.7408 5.61189 93.6008 5.90348 93.5478C6.19506 93.4948 6.4958 93.5313 6.76618 93.6527C6.88272 93.6961 6.98689 93.7675 7.06946 93.8605C7.15202 93.9535 7.21054 94.0653 7.23985 94.1862C7.26915 94.307 7.26837 94.4332 7.23758 94.5537C7.20679 94.6742 7.14692 94.7853 7.06322 94.8773C2.08876 100.685 0.839325 107.47 6.2407 112.165C10.6398 115.969 18.4599 118.034 25.1235 117.904C30.7054 117.976 36.2397 116.869 41.3647 114.657ZM41.3647 114.657C42.6109 117.12 39.9089 115.382 41.3647 114.676V114.657Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.27582 132.816C11.4382 136.527 22.9707 138.055 31.7131 136.942C37.2815 136.218 42.2189 134.771 44.3534 132.711C49.4134 126.39 48.0769 119.677 41.3093 116.011C41.1955 115.959 41.0968 115.879 41.0224 115.778C40.9479 115.678 40.9002 115.56 40.8835 115.436C40.8668 115.312 40.8818 115.185 40.9271 115.069C40.9724 114.952 41.0464 114.849 41.1423 114.768C41.3583 114.567 41.6312 114.437 41.9237 114.398C42.2162 114.358 42.5138 114.409 42.7758 114.545C50.2003 118.795 51.6296 126.755 46.2653 133.527V133.62C43.8709 135.958 38.4509 137.795 32.0286 138.612C22.6365 139.799 11.1842 138.908 4.59487 134.901L5.46721 135.068L4.48351 134.808C-1.93875 129.52 -0.954979 119.147 4.61345 113.061C4.82012 112.852 5.08674 112.713 5.37624 112.663C5.66573 112.614 5.96364 112.655 6.22829 112.783C6.34534 112.829 6.449 112.904 6.53009 113C6.61119 113.096 6.66714 113.211 6.69289 113.334C6.71864 113.458 6.71345 113.585 6.67771 113.706C6.64197 113.827 6.57687 113.937 6.48817 114.026C1.45802 119.611 -0.385414 128.066 5.27582 132.816Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M46.0982 133.045C52.1864 138.834 50.3302 146.758 42.9985 152.955C42.9306 153.027 42.8484 153.084 42.7572 153.122C36.5488 155.801 29.8624 157.195 23.1006 157.223C19.1522 157.443 15.1967 156.946 11.4255 155.757C-0.806526 150.951 0.993923 141.729 4.22361 134.14C4.36576 133.884 4.58806 133.682 4.85634 133.565C5.12462 133.447 5.42401 133.421 5.70851 133.491C6.30247 133.62 6.6366 134.103 6.43243 134.585C3.24953 140.785 1.19323 149.832 12.5948 154.161C16.0236 155.213 19.6135 155.64 23.1935 155.423C29.4617 155.411 35.6646 154.149 41.4393 151.712C48.0769 146.542 50.1332 138.728 44.2606 134.233C44.1706 134.144 44.103 134.034 44.0636 133.914C44.0242 133.793 44.0141 133.665 44.0341 133.54C44.054 133.415 44.1035 133.297 44.1784 133.194C44.2533 133.092 44.3515 133.009 44.4648 132.952C44.7129 132.796 45.0036 132.721 45.2963 132.738C45.5891 132.755 45.8694 132.862 46.0982 133.045Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.95434 100.852C6.98627 103.21 6.84245 105.825 7.54604 108.274C7.60511 108.503 7.57074 108.746 7.45065 108.95C7.33056 109.153 7.13452 109.301 6.90561 109.36C6.6767 109.419 6.4337 109.385 6.23007 109.264C6.02643 109.144 5.8788 108.948 5.81973 108.72C5.00973 105.888 5.18648 102.865 6.32092 100.147C6.36534 100.038 6.43127 99.9397 6.51476 99.8571C6.59825 99.7744 6.69762 99.7095 6.80682 99.6662C6.91603 99.6228 7.03287 99.602 7.15032 99.6049C7.26777 99.6079 7.38339 99.6345 7.4903 99.6832C7.70666 99.777 7.87698 99.9527 7.96397 100.172C8.05095 100.391 8.0475 100.636 7.95434 100.852Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.84082 120.966C6.00955 123.258 5.89296 125.749 6.50662 128.109C6.55945 128.323 6.53076 128.55 6.42607 128.744C6.32137 128.939 6.14817 129.087 5.94005 129.161C5.73193 129.235 5.50372 129.229 5.29973 129.145C5.09575 129.06 4.93057 128.902 4.83615 128.703C4.08926 126.004 4.19291 123.14 5.13308 120.502C5.16322 120.389 5.2156 120.282 5.28705 120.189C5.35849 120.096 5.44764 120.018 5.54943 119.959C5.65121 119.9 5.7635 119.862 5.88001 119.847C5.99651 119.832 6.11491 119.84 6.22826 119.871C6.45416 119.936 6.64532 120.088 6.76005 120.293C6.87477 120.498 6.9038 120.74 6.84082 120.966Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.4186 141.209C7.94969 143.665 8.42002 146.208 9.7365 148.334C9.86133 148.533 9.90207 148.774 9.8499 149.004C9.79773 149.233 9.65686 149.432 9.45803 149.558C9.35996 149.623 9.24989 149.667 9.13448 149.687C9.01907 149.708 8.90069 149.706 8.78634 149.68C8.67198 149.654 8.56409 149.605 8.46901 149.536C8.37394 149.468 8.29367 149.38 8.23303 149.28C6.67918 146.793 6.12227 143.811 6.67382 140.931C6.6919 140.815 6.73266 140.704 6.7938 140.604C6.85493 140.504 6.93527 140.417 7.03012 140.348C7.12496 140.279 7.23249 140.23 7.34654 140.202C7.46059 140.175 7.57887 140.17 7.69468 140.189C7.80998 140.207 7.92058 140.248 8.01982 140.309C8.11905 140.37 8.20496 140.451 8.27245 140.546C8.33995 140.641 8.3876 140.749 8.41271 140.863C8.43781 140.977 8.43984 141.095 8.4186 141.209Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M83.9077 151.062C79.8428 156.481 71.4345 163.383 65.7547 165.536C55.3217 169.246 44.019 169.763 33.2907 167.02C26.7427 165.43 20.9303 161.661 16.8082 156.332C16.3998 155.738 18.553 155.683 18.553 155.683C22.416 160.508 27.7674 163.92 33.7732 165.387C44.1323 168.045 55.0494 167.547 65.1235 163.958C70.562 161.917 78.5807 155.275 82.4786 150.098C82.6192 149.909 82.8289 149.783 83.062 149.748C83.2951 149.713 83.5324 149.772 83.7221 149.912C83.8895 150.047 84.0011 150.239 84.0354 150.452C84.0696 150.664 84.0241 150.882 83.9077 151.062Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M59.9264 89.8856C55.9131 90.8022 51.7448 90.8022 47.7315 89.8856C43.6197 89.1525 39.5637 88.1357 35.5924 86.8426C28.4277 90.9062 19.5367 92.7432 12.0379 89.5702C7.86158 87.8075 4.92881 85.5437 3.85224 81.109C3.21613 78.9955 3.16146 76.7497 3.69386 74.6078C4.22626 72.4658 5.32607 70.5068 6.87777 68.9368C16.7153 61.0322 26.4601 63.129 36.2606 63.1104C44.7803 63.1104 62.3951 61.0137 62.3951 61.0137C62.4745 60.9954 62.557 60.9954 62.6364 61.0137C80.0656 63.9454 85.4484 71.5531 90.3115 81.4245C93.337 87.6776 96.3439 95.0997 102.358 102.522C103.768 104.563 105.086 106.585 105.161 106.715C108.466 111.604 110.202 117.385 110.135 123.285C111.045 137.202 97.0308 157.464 74.2373 148.947C74.0158 148.863 73.8365 148.695 73.7391 148.479C73.6416 148.264 73.6338 148.018 73.7175 147.797C73.8012 147.575 73.9695 147.396 74.1853 147.299C74.4011 147.201 74.6468 147.193 74.8683 147.277C96.1212 155.2 109.207 136.385 108.279 123.415C108.367 117.821 106.734 112.335 103.602 107.699C103.602 107.699 102.265 105.658 100.836 103.579C94.7291 95.9161 91.7593 88.5311 88.5667 82.0739C83.9263 72.7963 78.8219 65.5041 62.3208 62.7022C60.7245 62.8878 44.2791 64.799 36.112 64.799C26.7014 64.799 17.3094 62.6466 7.88016 70.2171C6.57023 71.57 5.64816 73.25 5.21059 75.0814C4.77302 76.9127 4.83598 78.8279 5.39288 80.6266C6.33951 84.5046 8.88238 86.3045 12.539 87.8631C16.7024 89.3734 21.2121 89.6567 25.532 88.6796C28.7958 87.9199 31.9252 86.6686 34.8127 84.9685C34.9301 84.9185 35.0564 84.8927 35.184 84.8927C35.3116 84.8927 35.4379 84.9185 35.5552 84.9685C39.5542 86.2936 43.6417 87.3353 47.7873 88.0858C51.5147 88.9251 55.3822 88.9251 59.1097 88.0858C59.3337 88.0095 59.5788 88.0253 59.7911 88.1297C60.0035 88.2341 60.1656 88.4185 60.2419 88.6424C60.3182 88.8664 60.3025 89.1114 60.1981 89.3237C60.0936 89.5359 59.9091 89.698 59.6851 89.7743L59.9264 89.8856Z"
            fill="#304D63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.52994 93.9309C14.6923 97.6419 25.4023 98.347 34.1447 97.2337C39.7131 96.5101 44.6505 95.0627 46.785 93.0031C47.7412 91.8075 48.488 90.4586 48.9938 89.0137L51.314 89.1807C50.7233 90.898 49.8377 92.4992 48.6969 93.9123C46.3025 96.2688 40.8826 98.0873 34.4603 98.9037C25.1796 100.091 13.6158 99.2006 7.02647 95.1926L6.9151 95.0999C2.01577 90.1996 1.60451 83.2081 4.39077 77.2126C5.09611 75.821 5.04038 82.037 5.04038 82.037C4.46784 84.1484 4.49078 86.3769 5.10665 88.4761C5.72252 90.5752 6.90732 92.4631 8.52994 93.9309Z"
            fill="#304D63"
          />
        </svg>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    isOpen: Boolean,
    isLeft: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
