<template>
  <BaseButton
    v-if="languages.length > 1"
    location="home"
    class="langtoggle"
    is-quaternary
    @click="showModal('languages')"
  >
    <BaseIcon type="globe" />
    {{ currentLanguage }}
  </BaseButton>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentLanguage: 'getLanguage',
    }),
    ...mapState(['languages']),
  },
  methods: {
    ...mapMutations(['setLanguage']),
    addQueryArg(name, value) {
      const params = new URLSearchParams(window.location.search);

      params.set(name, value);

      return '?' + params.toString();
    },
  },
};
</script>
