var render = function (_h,_vm) {
var _obj, _obj$1;
var _c=_vm._c;return _c('div',[_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.props.isLeft),expression:"props.isLeft"}],class:( _obj = {
      hand: true
    }, _obj['is-color-' + _vm.props.color] = true, _obj['is-utopia'] =  true, _obj['is-open'] =  _vm.props.isOpen, _obj['is-left'] =  _vm.props.isLeft, _obj ),attrs:{"viewBox":"0 0 150 180"}},[_c('g',{staticClass:"hand--closed"},[_c('image',{attrs:{"width":"150","height":"180","href":require("../assets/img/utopia/closed-left.svg")}})]),_c('g',{staticClass:"hand--open"},[_c('image',{attrs:{"width":"150","height":"180","href":require("../assets/img/utopia/open-left.svg")}})])]),_c('svg',{directives:[{name:"show",rawName:"v-show",value:(!_vm.props.isLeft),expression:"!props.isLeft"}],class:( _obj$1 = {
      hand: true,
      'is-right': _vm.isRight
    }, _obj$1['is-color-' + _vm.props.color] = true, _obj$1['is-open'] =  _vm.props.isOpen, _obj$1 ),attrs:{"viewBox":"0 0 150 180"}},[_c('g',{staticClass:"hand--closed"},[_c('image',{attrs:{"width":"150","height":"180","href":require("../assets/img/utopia/closed-right.svg")}})]),_c('g',{staticClass:"hand--open"},[_c('image',{attrs:{"width":"150","height":"180","href":require("../assets/img/utopia/open-right.svg")}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }