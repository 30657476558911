import Vue from 'vue';
import Vuex from 'vuex';
import { Howler } from 'howler';
import axios from 'axios';

Vue.use(Vuex);
Vue.config.devtools = true;

const nonQuestions = [
  'home',
  'demosurvey',
  'levelChange',
  'end',
  'howThisWorks',
  'introsurvey',
  'recoveryNotice',
  'repeat',
];

export default new Vuex.Store({
  state: {
    id: 0,
    language: document.documentElement.lang || 'en',
    languages: [],

    answers: [],
    score: 0,

    currentScreen: '',
    activeModals: [],

    baseURL: '/',
    useAudio: false,

    isMobileLandscape: false,

    session: '',

    survey: {},
  },
  getters: {
    getLanguage(state) {
      return state.language;
      // return state.languages.find(entry => {
      //   return entry.slug === state.language;
      // });
    },

    getAnswer(state) {
      return question => {
        const entry = state.answers.find(entry => entry.question === question);

        return entry ? entry.answer : null;
      };
    },
    getSurvey(state, key) {
      return state.survey[key];
    },
  },
  mutations: {
    async setLanguage(state, language) {
      const id = state.survey.id;
      const newSurvey = await axios
        .get(`survey-${id}-${language}.json`)
        .then(res => res.data)
        .then(res => ({
          ...res.config,
          main_questions: res.main_questions,
          id: res.id,
          followup_interfaces: res.followup_interfaces,
          recovery_questions: res.recovery_questions,
          interrupt_questions: res.interrupt_questions,
          intro_survey: res.intro_survey,
          translations: res.translations,
          demographic_survey: res.demographic_survey,
          levels: res.levels.map((level, i) => ({ index: i, ...level })),
          languages: res.languages,
          share_config: res.share_config,
          surveyLanguage: res.surveyLanguage,
        }));

      state.survey = newSurvey;
      state.language = language;
    },

    setAvailLanguages(state, languages) {
      state.languages = languages;
    },

    saveAnswer(state, entry) {
      state.answers = [...state.answers, entry];
    },

    incrementScore(state) {
      state.score++;
    },
    decrementScore(state) {
      state.score--;
    },
    changeScore(state, result) {
      state.score = Math.max(0, state.score + (result ? 1 : -1));
    },

    goToScreen(state, name) {
      if (state.session) {
        if (!nonQuestions.includes(name)) {
          const questions = [
            ...state.survey.main_questions,
            ...state.survey.followup_interfaces,
            ...state.survey.interrupt_questions,
            ...state.survey.recovery_questions,
          ];
          const question = questions.find(one => one.id === name);
          this._vm.$log.event({
            event_type: 'change-screen',
            target: name,
            question_name: question ? question.name : name,
          });
        }
      }

      state.currentScreen = name;
    },

    showModal(state, name) {
      if (!state.activeModals.includes(name)) {
        state.activeModals.push(name);
      }
    },
    hideModal(state, name) {
      const activeModals = [...state.activeModals];
      const index = activeModals.indexOf(name);
      if (index >= 0) {
        activeModals.splice(index, 1);
        state.activeModals = activeModals;
      }
    },

    enableAudio(state) {
      state.useAudio = true;
      Howler.volume(1);
    },
    disableAudio(state) {
      state.useAudio = false;
      Howler.volume(0);
    },
    toggleAudio(state) {
      state.useAudio = !state.useAudio;
      Howler.volume(state.useAudio ? 1 : 0);
    },

    setMobileLandscape(state, value) {
      state.isMobileLandscape = value;
    },

    setSession(state, value) {
      state.session = value;
    },

    reset(state) {
      state.answers = [];
      state.score = 0;
      state.activeModals = [];
      state.currentScreen = '';
    },
    shuffle(state, value) {
      console.log('shuffle');
      console.log(value);
      console.log(state);
    },
  },
});
