<template>
  <BaseModal is-mini name="languages">
    <div class="language-picker">
      <h2>{{ $l10n('select-language') }}</h2>

      <a
        v-for="language in languages"
        :key="language.slug"
        :class="{
          'language-picker__option': true,
          'is-current': currentLanguage === language,
        }"
        @click="changeLang(language.slug)"
        >{{ language.name }}</a
      >
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['languages']),
    ...mapGetters({
      currentLanguage: 'getLanguage',
    }),
  },
  methods: {
    ...mapMutations(['setLanguage']),
    async changeLang(lang) {
      this.setLanguage(lang);
      this.hideModal('languages');
    },
  },
};
</script>

<style></style>
