<template functional>
  <div>
    <svg
      v-show="props.isLeft"
      :class="{
        hand: true,
        ['is-color-' + props.color]: true,
        'is-utopia': true,
        'is-open': props.isOpen,
        'is-left': props.isLeft,
      }"
      viewBox="0 0 150 180"
    >
      <g class="hand--closed">
        <image
          width="150"
          height="180"
          href="../assets/img/utopia/closed-left.svg"
        />
      </g>
      <g class="hand--open">
        <image
          width="150"
          height="180"
          href="../assets/img/utopia/open-left.svg"
        />
      </g>
    </svg>
    <svg
      v-show="!props.isLeft"
      :class="{
        hand: true,
        'is-right': isRight,
        ['is-color-' + props.color]: true,
        'is-open': props.isOpen,
      }"
      viewBox="0 0 150 180"
    >
      <g class="hand--closed">
        <image
          width="150"
          height="180"
          href="../assets/img/utopia/closed-right.svg"
        />
      </g>
      <g class="hand--open">
        <image
          width="150"
          height="180"
          href="../assets/img/utopia/open-right.svg"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    isOpen: Boolean,
    isLeft: Boolean.isRequired,
  },
  computed: {
    isRight() {
      return !this.isLeft;
    },
  },
};
</script>
