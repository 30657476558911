<template>
  <g>
    <path
      class="timer"
      :fill="fill"
      :fill-opacity="fillOpacity"
      :d="path"
      :transform="`translate(${width / 2},${height / 2})`"
    />
    <g
      class="timer__count"
      :transform="`matrix(1, 0, 0, 1, ${width / 2 - 20}, ${height - 65})`"
    >
      <circle cx="20" cy="20" r="19.5" />
      <text x="20" y="25">{{ $l10n(remaining) }}</text>
    </g>
  </g>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    delay: {
      type: Number,
      default: 0,
    },
    start: {
      type: Boolean,
      default: true,
    },
    paused: Boolean,
    fill: {
      type: String,
      default: '',
    },
    fillOpacity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      size: Math.max(this.width, this.height) / 2,
      elapsed: 0,
    };
  },
  computed: {
    remaining() {
      const remaining = this.duration - this.elapsed + this.delay;

      return Math.ceil(Math.min(remaining, this.duration) / 1000);
    },
    path() {
      const r = this.size;
      const d = r * 2;

      const slice = ((this.elapsed - this.delay) / this.duration) * 360;

      if (slice <= 0) {
        // Custom path when doing 100%
        return `M 0,0 m -${r},0 a ${r},${r} 0 1,0 ${d},0 a ${r},${r} 0 1,0 -${d},0`;
      }

      const a = (slice * Math.PI) / 180;
      const x = Math.sin(a) * r;
      const y = Math.cos(a) * (-1 * r);

      const arc = slice > 180 ? 0 : 1;

      return `M 0,0 v -${r} A ${r},${r} 0 ${arc} 0 ${x},${y} z`;
    },
  },
  watch: {
    start(start) {
      if (start) {
        requestAnimationFrame(t => {
          this.started = t;
          this.tick(t);
        });
      }
    },
    paused(paused) {
      if (!paused) {
        requestAnimationFrame(t => {
          this.started = t - this.elapsed;
          this.tick(t);
        });
      }
    },
  },
  methods: {
    tick(timestamp) {
      if (!this.start || this.paused) {
        return;
      }

      this.elapsed = timestamp - this.started;

      if (this.elapsed >= this.duration + this.delay) {
        this.$emit('done');
        return;
      }

      requestAnimationFrame(t => this.tick(t));
    },
  },
};
</script>
