<template>
  <div class="full_carousel">
    <div v-if="withPagination" class="full_carousel__pagination">
      <button
        v-for="(slide, index) in $slots.default"
        :key="index"
        :class="{
          'full_carousel__pagination-dot': true,
          'is-current-slide': index === position,
        }"
        @click="gotoSlide(index)"
      >
        <ScreenReaderText>Go to slide {{ index }}</ScreenReaderText>
      </button>
    </div>
    <div
      ref="scroller"
      class="full_carousel__slides-wrap"
      :style="{
        '--count': $slots.default.length,
      }"
    >
      <div ref="wrapper" class="full_carousel__slides">
        <Slide
          v-for="(slide, index) in $slots.default"
          :key="index"
          :content="slide"
          :is-current="index === position"
          :class="`full_carousel__slide--${index + 1}`"
          @enter="onEnter(index)"
          @select="onSelect(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseCarouselSlide from './BaseCarouselSlide.vue';

const tabletQuery = window.matchMedia('(min-width:48em)');

const Slide = {
  props: {
    content: {
      type: Object,
      required: true,
    },
    isCurrent: Boolean,
  },
  render(createElement) {
    return createElement(
      BaseCarouselSlide,
      {
        on: this.$listeners,
        class: { 'is-current-slide': this.isCurrent },
      },
      [this.content]
    );
  },
};

export default {
  components: {
    Slide,
  },
  props: {
    position: {
      type: Number,
      default: 0,
    },
    withPagination: Boolean,
  },
  emits: ['update'],
  watch: {
    position(index) {
      this.gotoSlide(index);
    },
  },
  mounted() {
    this.gotoSlide(this.position);
  },
  methods: {
    gotoSlide(index) {
      const { scroller, wrapper } = this.$refs;
      const slide = wrapper.children[index];
      const init = wrapper.children[0].offsetLeft;

      if (slide) {
        const scrollDistance = slide.offsetLeft - init;
        // scroller.scrollLeft = scrollDistance;
        scroller.scrollTo({
          top: 0,
          left: scrollDistance,
          behavior: 'smooth',
        });
      }
      this.$emit('update', index);
    },
    onEnter(index) {
      if (!tabletQuery.matches) {
        // Added this in because the "on enter" event was causing the slide scroller
        // to fire additional times (which was causing the filled in ball to bounce
        // around when skipping from 0 to 2 for example)
        if (document.body.classList.contains('user-is-tabbing')) {
          this.$emit('update', index);
        }
      }
    },
    onSelect(index) {
      if (tabletQuery.matches) {
        this.$emit('update', index);
      }
    },
  },
};
</script>
